<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <div class="profile scrollable no-show-scroll" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600">
                Storage viewer
            </h6>
        </div>

        <div class="row" style="min-height: 100vh;">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <Tree id="my-tree-id" ref="my-tree" :custom-options="myCustomOptions" :custom-styles="myCustomStyles"
                    :nodes="treeDisplayData"></Tree>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <ul class="list-unstyled text-start ms-auto" style="column-count: 3;" v-if="files && files.length > 0">
                    <li v-for="file in files" v-bind:key="file.id">
                        <a href="#" v-on:click="downloadFile(file.id, file.fileName)">
                            <span class="text-warning">{{ file.timeStampFolder }}</span>/{{ file.fileName }}
                        </a>
                        <a href="#" @click="copy(file.folderPath)"><i
                                class="fa-solid fa-copy ms-1 text-success"></i></a>
                    </li>
                </ul>
                <p v-else>
                    No files found in folder!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import LoadingIndicator from "../components/LoadingIndicator.vue";
import 'vue-toast-notification/dist/theme-sugar.css';
import StorageViewerService from "../services/storageViewerService";
import { useToast } from 'vue-toast-notification';
// eslint-disable-next-line no-unused-vars
import Tree from 'vuejs-tree'

export default {
    name: 'StorageViewerView',
    data() {
        return {
            loading: false,
            newTree: {},
            treeDisplayData: [

            ],
            files: []
        }
    },
    computed: {
        myCustomStyles() {
            return {
                tree: {
                    style: {
                        height: "auto",
                        maxHeight: "300px",
                        overflowY: "visible",
                        display: "inline-block",
                        textAlign: "left",
                    },
                },
                row: {
                    style: {
                        width: "500px",
                        cursor: "pointer",
                    },
                    child: {
                        class: "",
                        style: {
                            height: "35px",
                        },
                        active: {
                            style: {
                                height: "35px",
                            },
                        },
                    },
                },
                rowIndent: {
                    paddingLeft: "20px",
                },
                text: {
                    // class: "" // uncomment this line to overwrite the 'capitalize' class, or add a custom class
                    style: {},
                    active: {
                        style: {
                            "font-weight": "bold",
                            color: "#2ECC71",
                        },
                    },
                },
            };
        },
        myCustomOptions() {
            return {
                treeEvents: {
                    expanded: {
                        state: false,
                    },
                    collapsed: {
                        state: false,
                    },
                    selected: {
                        state: true,
                        fn: this.mySelectedFunction,
                    },
                    checked: {
                        state: false,
                        fn: null,
                    },
                },
                events: {
                    expanded: {
                        state: true,
                    },
                    selected: {
                        state: true,
                    },
                    checked: {
                        state: false,
                    },
                    editableName: {
                        state: false,
                        calledEvent: "expanded",
                    },
                },
                addNode: {
                    state: false,
                    fn: null,
                    appearOnHover: false,
                },
                editNode: { state: false, fn: null, appearOnHover: false },
                deleteNode: {
                    state: false,
                    fn: null,
                    appearOnHover: true,
                },
                showTags: true,
            };
        },
    },
    components: {
        LoadingIndicator,
        Tree
    },
    created() {
        this.getFolders();
    },
    methods: {
        copy(value) {
            navigator.clipboard.writeText(value);
        },
        getFolders() {
            this.loading = true;
            StorageViewerService.getStorage().then((storage) => {
                this.treeDisplayData = storage.items;
                this.loading = false;
            });
        },
        mySelectedFunction(nodeId) {
            var selectedNode = this.$refs["my-tree"].getSelectedNode();
            this.files = [];

            if (selectedNode && selectedNode.lastNode) {
                this.loading = true;
                StorageViewerService.getStorageFiles(nodeId).then((files) => {
                    this.files = files;
                    this.loading = false;
                });
            }

        },
        downloadFile(id, fileName) {
            this.loading = true;
            StorageViewerService.downloadFile(id).then(
                (response) => {
                    this.loading = false;
                    const url = window.URL.createObjectURL(new Blob([response]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(() => {
                    this.loading = false;
                    const $toast = useToast();
                    $toast.error('Unable to download file!');
                });
        }
    }
}
</script>

<style scoped></style>