<template>
    <div class="template scrollable no-show-scroll">

        <LoadingIndicator v-if="loading"></LoadingIndicator>
        <HeadingComponent title="Templates" description="All your templates" action="Create a new template"
            action-enabled="false" action-modal="">
        </HeadingComponent>

        <ContentWrapper>

            <SearchBarComponent title="All templates"></SearchBarComponent>

            <div class="row me-5 mt-4">
                <div class="col-xl-4 col-md-6 mb-3" v-for="template in filteredTemplates" :key="template.id">
                    <router-link :to="{
                        name: 'TemplateDetails',
                        params: { id: template.id }
                    }">
                        <div class="card shadow-sm">
                            <div class="card-header">
                                <div class="d-sm-flex align-items-center justify-content-between">
                                    <h6 class="m-0 fw-bold text-gray-600">
                                        <img src="../assets/images/sap.svg" /> {{ template.name }}
                                    </h6>
                                </div>
                            </div>
                            <div class="card-body" style="min-height: 100px;color:#7b7b7b;">
                                <span>{{ template.description }}</span>
                            </div>
                            <div class="card-footer">
                                <h4 class="h6 text-info">
                                    <div class="d-sm-flex align-items-center justify-content-between">
                                        <span><i class="fa-solid fa-clipboard-list"></i> Tasks: {{ template.tasks.length
                                            }}</span>
                                        <span
                                            :class="template.tenantName == 'Global' ? 'text-success' : 'text-warning'">{{
                                                template.tenantName }}</span>
                                    </div>
                                </h4>
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
        </ContentWrapper>
    </div>
</template>

<script>
// @ is an alias to /src
import TemplatesService from "../services/templatesService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import HeadingComponent from '../components/HeadingComponent.vue'
import SearchBarComponent from '../components/SearchBarComponent.vue'
import ContentWrapper from '../components/ContentWrapper.vue'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
    name: "TemplatesView",
    data: () => {
        return {
            templates: [],
            connections: [],
            searchTerm: null,
            filteredTemplates: [],
            name: null,
            description: null,
            loading: true,
            orderBy: false,
            sortText: '<i class="fa-sharp fa-solid fa-arrow-up"></i>',
        };
    },
    components: {
        LoadingIndicator, HeadingComponent, SearchBarComponent, ContentWrapper
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredTemplates = this.templates.filter(x => x.name.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1 || x.description?.toLowerCase()?.indexOf(this?.searchTerm?.toLowerCase()) > -1 || x.tenantName?.toLowerCase()?.indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredTemplates = this.templates
            }
        }
    },
    created() {
        this.loading = false;
        this.getTemplates();
    },
    methods: {
        getTemplates() {
            TemplatesService.getTemplates(this.orderBy).then(
                (templates) => {
                    this.templates = templates;
                    this.filteredTemplates = this.templates;
                    this.loading = false;
                });
        },
        sort() {
            this.orderBy = !this.orderBy;
            this.sortText = this.orderBy ? '<i class="fa-sharp fa-solid fa-arrow-down"></i>' : '<i class="fa-sharp fa-solid fa-arrow-up"></i>';
            this.getTemplates();
        },
        createTemplate() {

            let template =
            {
                Name: this.name,
                Description: this.description
            };

            TemplatesService.createTemplate(template).then(
                (id) => {
                    this.$router.push({ name: 'TemplateDetails', params: { id: id } });
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        }
    },
};
</script>
