<template>
    <div class="edit-profile" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600" v-if="!loading">
                Profile / <span class="fw-bold"> {{ user.firstName }} {{ user.lastName }} </span> with role
                <span class="fw-bold">{{ user.mainRole }}</span> in <span class="fw-bold">{{
                    user.tenantName }}</span>
            </h6>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-6 offset-lg-4 col-md-6 col-sm-12 mx-auto mb-4 mt-4">
                <div class="card">
                    <div class="card-header">
                        Edit profile
                    </div>
                    <div class="card-body">
                        <form class="user row g-1">
                            <div class="form-floating col-md-6">
                                <input type="text" v-model="user.firstName" class="form-control"
                                    :class="{ 'border border-danger': firstNameError }" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="First name" id="firstName-new"
                                    placeholder="First name">
                                <label for="firstName-new">First name</label>
                            </div>
                            <div class="form-floating mb-3 col-md-6">
                                <input type="text" v-model="user.lastName" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Last name" class="form-control" id="lastName-new"
                                    :class="{ 'border border-danger': lastNameError }" placeholder="Last name">
                                <label for="lastName-new">Last name</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" v-model="user.address" class="form-control" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Address line 1" id="address-new"
                                    placeholder="Address line 1">
                                <label for="address-new">Address line 1</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" v-model="user.streetNumber" class="form-control"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Address line 2"
                                    id="StreetNumber-new" placeholder="Address line 2">
                                <label for="StreetNumber-new">Address line 2</label>
                            </div>
                            <div class="form-floating mb-3 col-md-6 col-lg-6">
                                <input type="text" v-model="user.city" class="form-control" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="City" id="City-new" placeholder="City">
                                <label for="City-new">City</label>
                            </div>
                            <div class="form-floating mb-3 col-md-6 col-lg-6">
                                <input type="text" v-model="user.postCode" class="form-control" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Postal code" id="postCode-new"
                                    placeholder="Postal code">
                                <label for="postCode-new">Postal code</label>
                            </div>

                            <div class="form-floating mb-3 col-md-6 col-lg-6">
                                <input type="text" v-model="user.country" class="form-control" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Country" id="Country-new" placeholder="Country">
                                <label for="Country-new">Country</label>
                            </div>
                            <div class="form-floating mb-3 col-md-6 col-lg-6">
                                <input type="text" v-model="user.phoneNumber" class="form-control"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Phone number"
                                    id="phoneNumber-new" placeholder="Phone number">
                                <label for="phoneNumber-new">Phone number</label>
                            </div>

                            <div class="mb-3">
                                <a href="#" class="btn btn-primary btn-block" @click="saveProfile()">
                                    Save
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import UsersService from "../services/usersService";

export default {
    name: "ProfileEditView",
    data: () => {
        return {
            user: {
                firstName: null,
                lastName: null,
                phoneNumber: null,
                postCode: null,
                address: null,
                city: null,
                country: null,
                streetNumber: null
            },
            loading: true,
            firstNameError: false,
            lastNameError: false
        };
    },
    components: {
        LoadingIndicator,
    },
    created() {
        this.getProfile();
    },
    methods: {
        getProfile() {
            UsersService.getCurrent().then(
                (user) => {
                    this.user = user;
                    this.loading = false;
                })
        },
        saveProfile() {
            this.loading = true;
            UsersService.updateMe(this.user).then(
                () => {
                    const $toast = useToast();

                    $toast.success('Profile is saved!');
                    this.getProfile();
                })
        },
    },
};
</script>
