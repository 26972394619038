<template>
    <div class="clients scrollable no-show-scroll">
        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <HeadingComponent title="Partner clients" description="Add your clients here to start adding users and projects"
            action="Create a new client" action-modal="#newClientModal" action-enabled="true"> </HeadingComponent>

        <!-- Create new client Modal-->
        <div class="modal fade" id="newClientModal" tabindex="-1" role="dialog" aria-labelledby="newClientModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Create a client</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                            
                        </button>
                    </div>
                    <div class="modal-body">
                        <h5 class="modal-title" id="exampleModalLabel">Setup client</h5>
                        <form class="user mt-5">
                            <div class="mb-3 row">
                                <div class="col-sm-12 mb-6 mb-sm-0">
                                    <input type="text" v-model="clientDto.name" class="form-control form-control-user"
                                        :class="{ 'border border-danger': nameError }" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Name" id="name-new" placeholder="Name">
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <div class="col-sm-12 mb-12 mb-sm-0">
                                    <textarea type="text" class="form-control small shadow-sm" rows="8"
                                        v-model="clientDto.description" placeholder="Client description"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Client description"
                                        aria-label="Update" aria-describedby="basic-addon2" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <a class="btn btn-primary" data-bs-dismiss="modal" @click="createClient()">Create</a>
                    </div>
                </div>
            </div>
        </div>

        <ContentWrapper>

            <SearchBarComponent title="All clients"></SearchBarComponent>

            <div class="row me-5 mt-4">
                <div class="col-xl-4 col-md-6 mb-3" v-for="client in filteredClients" :key="client.id">
                    <div class="card shadow-sm" style="min-height:240px;">
                        <div class="card-header bg-success" :class="!client.isActive ? 'bg-warning' : 'bg-default'">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <h6 class="m-0 fw-bold" :class="client.isActive ? 'text-gray-600' : ''">
                                    {{ client.name }}
                                </h6>
                                <ul class="navbar-nav ms-auto">
                                    <li class="nav-item dropdown no-arrow">
                                        <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown" role="button"
                                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                                            aria-labelledby="projectDropdown">
                                            <a class="dropdown-item text-warning" href="#" data-bs-dismiss="modal"
                                                data-bs-toggle="modal" data-bs-target="#editClientWizardModal"
                                                v-on:click="selectEditClient(client.id)">
                                                <i class="fa fa-duotone fa-pen-to-square"></i>
                                                Edit client
                                            </a>
                                            <a class="dropdown-item text-danger" v-if="client.isActive" href="#"
                                                data-bs-dismiss="modal" v-on:click="selectClient(client.id)"
                                                data-bs-toggle="modal" data-bs-target="#deactivateClientModal">
                                                <i class="fa-solid fa-trash-can"></i>
                                                Deactivate client
                                            </a>
                                            <a class="dropdown-item text-success" v-else href="#" data-bs-dismiss="modal"
                                                v-on:click="activateClient(client.id)">
                                                <i class="fa-solid fa-rotate-left"></i>
                                                Activate client
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="card-body">
                            <h1 class="h6">{{ client.description }}</h1>
                        </div>
                        <div class="card-footer">
                            <h6>
                                <span><i class="fa-solid fa-users"></i> All: {{ client.allUsersCount }} </span> |
                                <span class="text-warning"><i class="fa-solid fa-users"></i> Admins: {{
                                    client.adminCount }}
                                </span> |
                                <span class="text-primary"><i class="fa-solid fa-users"></i> Users: {{
                                    client.regularUserCount }} </span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="deactivateClientModal" tabindex="-1" role="dialog"
                aria-labelledby="deactivateClientModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6>Are you sure you want to deactivate client?</h6>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                
                            </button>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <a class="btn btn-primary" data-bs-dismiss="modal"
                                v-on:click="deactivateClient()">Deactivate</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Edit client-->
            <div class="modal fade" id="editClientWizardModal" tabindex="-1" role="dialog"
                aria-labelledby="editClientWizardModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                                
                            </button>
                        </div>
                        <div class="modal-body">
                            <h5 class="modal-title" id="exampleModalLabel">Setup client</h5>
                            <form class="user mt-5">
                                <div class="mb-3 row">
                                    <div class="col-sm-12 mb-6 mb-sm-0">
                                        <input type="text" v-model="selectedClient.name"
                                            class="form-control form-control-user" data-bs-toggle="tooltip"
                                            :class="{ 'border border-danger': nameError }" data-bs-placement="top"
                                            title="Name" id="name-new" placeholder="Name">
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <div class="col-sm-12 mb-12 mb-sm-0">
                                        <textarea type="text" class="form-control small shadow-sm" rows="8"
                                            v-model="selectedClient.description" placeholder="Client description"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Client description"
                                            aria-label="Update" aria-describedby="basic-addon2" />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <div class="row">
                                <div class="col-md-12 mb-3 mb-sm-0">
                                    <a href="#" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="updateClient()">
                                        Save
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ContentWrapper>
    </div>
</template>

<script>
// @ is an alias to /src
import ClientsService from "../services/clientsService";
import UsersService from "../services/usersService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import HeadingComponent from '../components/HeadingComponent.vue'
import SearchBarComponent from '../components/SearchBarComponent.vue'
import ContentWrapper from '../components/ContentWrapper.vue'
import { useRoute } from 'vue-router'

export default {
    name: "PartnerClientsView",
    components: { LoadingIndicator, HeadingComponent, SearchBarComponent, ContentWrapper },
    data: () => {
        return {
            clients: [],
            searchTerm: null,
            filteredClients: [],
            loading: true,
            orderBy: false,
            sortText: '<i class="fa-sharp fa-solid fa-arrow-up"></i>',
            selectedClientId: null,
            selectedClient: {
                name: null,
                description: null,
                batchSize: null,
                joinChunkSize: null,
                admins: []
            },
            clientDto: {
                name: null,
                description: null,
                isPartner: true
            },
            selectedAdminId: null,
            nameError: false,
            clientId: null
        };
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredClients = this.clients.filter(x => x.name.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1 || x.description?.toLowerCase()?.indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredClients = this.clients
            }
        },
        'clientDto.name': function (newTerm) {
            if (!newTerm) {
                this.nameError = true;
            } else {
                this.nameError = false;
            }
        },
        'selectedClient.name': function (newTerm) {
            if (!newTerm) {
                this.nameError = true;
            } else {
                this.nameError = false;
            }
        },
    },
    created() {
        const route = useRoute();
        this.clientId = route.params.id;

        this.getClients();
    },
    methods: {
        getClients() {
            ClientsService.getClientsFromPartner(this.clientId, this.orderBy).then((clients) => {
                this.clients = clients;
                this.filteredClients = clients;
                this.loading = false;
            });
        },
        sort() {
            this.orderBy = !this.orderBy;
            this.sortText = this.orderBy ? '<i class="fa-sharp fa-solid fa-arrow-down"></i>' : '<i class="fa-sharp fa-solid fa-arrow-up"></i>';
            this.getClients();
        },
        selectClient(id) {
            this.selectedClientId = id;
        },
        deactivateClient() {
            this.loading = true;
            ClientsService.deleteClient(this.selectedClientId).then(() => {
                const $toast = useToast();
                $toast.success('Client deactivated!');
                this.getClients();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        updateClient() {
            this.loading = true;

            if (!this.validateUpdateClient()) {
                this.loading = false;
                return;
            }

            ClientsService.updateClient(this.selectedClient.id, this.selectedClient).then(() => {
                const $toast = useToast();
                $toast.success('Update successful!');
                this.getClients();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        validateCreateClient() {
            let isOk = true;

            if (!this.clientDto.name) {
                this.nameError = true;
                isOk = false;
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        },
        validateUpdateClient() {
            let isOk = true;

            if (!this.selectedClient.name) {
                this.nameError = true;
                isOk = false;
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        },
        selectEditClient(id) {
            this.selectedClient = this.clients.find(x => x.id == id);
        },
        createClient() {
            this.loading = true;

            if (!this.validateCreateClient()) {
                this.loading = false;
                return;
            }

            ClientsService.createClient(this.clientDto).then(() => {
                const $toast = useToast();
                $toast.success('Client created!');
                this.getClients();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        activateClient(id) {
            this.loading = true;
            ClientsService.activateClient(id).then(() => {
                const $toast = useToast();
                $toast.success('Client activated!');
                this.getClients();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        assignAdmin() {
            this.loading = true;
            UsersService.assignAdmin(this.selectedAdminId, this.selectedClient.id).then(() => {
                const $toast = useToast();
                $toast.success('Admin added successfully!');
                this.getClients();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        removeAdmin(adminId) {
            this.loading = true;
            UsersService.removeAdmin(adminId, this.selectedClient.id).then(() => {
                const $toast = useToast();
                $toast.success('Admin removed successfully!');
                this.getClients();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
    }
};
</script>
