<template>
  <nav class="sb-topnav navbar navbar-expand bg-light">
    <!-- Navbar Brand-->
    <!-- Sidebar - Brand -->
    <router-link to="/projects" class="navbar-brand">
      <div class="sidebar-brand-text">
        HuroData
      </div>
    </router-link>
    <!-- Sidebar Toggle-->
    <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" v-on:click="sidebarToggle()"
      href="#!"><svg class="svg-inline--fa fa-bars fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas"
        data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
        <path fill="currentColor"
          d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
        </path>
      </svg><!-- <i class="fas fa-bars"></i> Font Awesome fontawesome.com --></button>
    <!-- Navbar Search-->
    <form class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
      <div class="input-group">
        <select v-if="isAdmin" v-model="tenantId" class="form-select form-select-sm" data-bs-toggle="tooltip"
          style="min-width: 220px;" data-bs-placement="top" title="Client context" id="tenant-edit"
          placeholder="Client context">
          <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
        </select>
        <a v-if="isAdmin" class="btn btn-sm btn-primary ms-2" v-on:click="changeContext()">Change</a>
      </div>
    </form>
    <!-- Navbar-->
    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle custom-dropdown-toggle" id="navbarDropdown" href="#" role="button"
          data-bs-toggle="dropdown" aria-expanded="false"> {{ name }} <svg class="svg-inline--fa fa-user fa-w-14 fa-fw"
            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
            <path fill="currentColor"
              d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
            </path>
          </svg><!-- <i class="fas fa-user fa-fw"></i> Font Awesome fontawesome.com --></a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
          <li> <router-link active-class="none" class="dropdown-item" style="font-weight: 100;"
              exact-active-class="none" to="/edit-profile"><i class="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>
              Profile
            </router-link></li>
          <li> <router-link active-class="none" style="font-weight: 100;" class="dropdown-item"
              exact-active-class="none" to="/change-password"><i class="fa-solid fa-key me-2 text-gray-400"></i>
              Change password
            </router-link></li>
          <li> <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#logoutModal">
              <i class="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
              Logout
            </a></li>
        </ul>
      </li>
    </ul>
  </nav>
  <!-- Page Wrapper -->
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <nav class="sb-sidenav accordion sb-sidenav-light mt-5" id="sidenavAccordion">
        <div class="sb-sidenav-menu">
          <div class="nav">
            <router-link to="/projects" class="nav-link" v-if="currentUser">
              <div class="sb-nav-link-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-calendar-minus" viewBox="0 0 16 16">
                  <path d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                  <path
                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                </svg><!-- <i class="fas fa-tachometer-alt"></i> Font Awesome fontawesome.com --></div>
              Projects
            </router-link>
            <router-link to="/connections" class="nav-link" v-if="currentUser">
              <div class="sb-nav-link-icon"><i class="fa-solid fa-network-wired"></i></div>
              Connections
            </router-link>
            <router-link to="/templates" class="nav-link" v-if="isAdmin || isClientAdmin">
              <div class="sb-nav-link-icon"><i class="fa-solid fa-rectangle-list"></i></div>
              Templates
            </router-link>
            <router-link to="/partners" class="nav-link" v-if="isSuperAdmin">
              <div class="sb-nav-link-icon"><i class="fa-regular fa-handshake"></i></div>
              Partners
            </router-link>
            <router-link to="/clients" class="nav-link" v-if="isAdmin">
              <div class="sb-nav-link-icon"><i class="fa-solid fa-building-columns"></i></div>
              Clients
            </router-link>
            <router-link to="/users" class="nav-link" v-if="isAdmin || isClientAdmin">
              <div class="sb-nav-link-icon"><i class="fa-solid fa-users"></i></div>
              Users
            </router-link>
            <router-link to="/storage" class="nav-link" v-if="isSuperAdmin">
              <div class="sb-nav-link-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-file-arrow-down" viewBox="0 0 16 16">
                  <path
                    d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
                  <path
                    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                </svg></div>
              Storage
            </router-link>
            <router-link to="/settings" class="nav-link" v-if="isAdmin">
              <div class="sb-nav-link-icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                  fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                  <path
                    d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </svg></div>
              Settings
            </router-link>
            <router-link to="/projects" class="nav-link ps-3 mt-5">
              <img v-bind:src=logo />
            </router-link>
          </div>
        </div>
      </nav>
    </div>
    <div id="layoutSidenav_content">
      <main style="background-color: #f8f9fa;">
        <div class="container-fluid" style="background-color: #f8f9fa;">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>

  <!-- Logout Modal-->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          Select "Logout" below if you are ready to end your current session.
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary" data-bs-dismiss="modal" @click="logout()">Logout</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Bootstrap core JavaScript-->
</template>
<script>

// @ is an alias to /src
import { authStore } from './../stores/authStore'
import ClientsService from "../services/clientsService";
import UsersService from "../services/usersService";
import AuthService from "../services/authService";
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';

export default {
  name: 'LayoutView',
  data: () => {
    return {
      clients: [],
      tenantId: localStorage.getItem("tenantId"),
      currentUser: null,
      isAdmin: false,
      isSuperAdmin: false,
      isClientAdmin: false,
      name: null,
      logo: null
    }
  },
  async created() {
    this.getLogo();
    this.getClients();
    UsersService.getCurrent().then(
      (user) => {
        this.currentUser = user;
        this.isAdmin = user?.roles?.filter(x => x == "SuperAdmin" || x == "PartnerAdmin").length > 0
        this.isSuperAdmin = user?.roles?.filter(x => x == "SuperAdmin").length > 0
        this.isClientAdmin = user?.roles?.filter(x => x == "ClientAdmin").length > 0
        this.name = user?.firstName + " " + user?.lastName;
      }
    );
  },
  methods: {
    logout() {
      const authStoreInstance = authStore();
      this.$socket.stop();
      authStoreInstance.logout()
      this.$router.push("/login");
    },
    sidebarToggle() {
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    },
    getClients() {
      ClientsService.getNonOrderedClients().then((clients) => {
        this.clients = clients;
      });
    },
    changeContext() {

      if (this.tenantId != null && this.tenantId > 0) {
        UsersService.changeContext(this.tenantId).then((token) => {
          AuthService.changeToken(token);
          window.location = "/projects";
        })
          .catch(error => {
            this.loading = false;
            console.log(error.message)
            const $toast = useToast();
            $toast.error('There was an error, please try again later or contact support');
          })

      }
    },
    getLogo() {
      ClientsService.getLogo().then((logo) => {
        this.logo = logo;
      });
    }
  }
}
</script>
<style>

</style>
