<template>
  <div class="project-details scrollable no-show-scroll" v-cloak>
    <LoadingIndicator v-if="loading"></LoadingIndicator>

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="h6 text-gray-600">Projects / {{ project.name }}</h6>
    </div>

    <!-- Content Row -->
    <div class="row hd-bg-color" v-cloak style="min-height: 100vh">
      <div class="col-lg-5 offset-lg-4 col-sm-12 mx-auto mb-4 mt-4">
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-sm-flex align-items-center justify-content-between">
              <h1 class="m-0 fw-bold">
                {{ project.name }}
              </h1>
              <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown no-arrow">
                  <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown" role="button"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h6><i class="fa-solid fa-ellipsis"></i></h6>
                  </a>
                  <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                    aria-labelledby="projectDropdown">
                    <a class="dropdown-item text-success" href="#" v-if="!hasMarkedForExtraction"
                      v-on:click="extract()">
                      <i class="fa fa-solid fa-play"></i>
                      Extract
                    </a>
                    <a class="dropdown-item text-success" href="#" v-if="hasMarkedForExtraction"
                      v-on:click="stopExtract()">
                      <i class="fa fa-solid fa-play"></i>
                      Stop extraction
                    </a>
                    <a class="dropdown-item text-info" href="#" v-on:click="copyProject()">
                      <i class="fa fa-regular fa-copy"></i>
                      Copy project
                    </a>
                    <a class="dropdown-item text-warning" href="#" data-bs-dismiss="modal" data-bs-toggle="modal"
                      data-bs-target="#updateProjectModal">
                      <i class="fa fa-duotone fa-pen-to-square"></i>
                      Edit project
                    </a>
                    <a class="dropdown-item text-danger" href="#" data-bs-dismiss="modal" data-bs-toggle="modal"
                      data-bs-target="#removeProjectModal">
                      <i class="fa-solid fa-trash-can"></i>
                      Remove project
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="d-sm-flex align-items-center mt-2">
              <span class="m-0 fw-bold badge text-bg-light p-2 border">
                <i class="fa fa-duotone fa-user"></i>
                {{ project.createdBy.firstName }}
                {{ project.createdBy.lastName }} at
                <span style="color: #198ada"> {{ project.tenant.name }}</span>
              </span>
              <span style="float: left" class="ms-2 m-0 fw-bold badge bg-info p-2 border">
                <i class="fa fa-light fa-calendar"></i> Created on
                {{ project.created }}
              </span>
            </div>
          </div>
        </div>

        <div class="card shadow-sm mt-3">
          <div class="card-body">
            <ConnectionComponent :project="project" @connectionChanged="getProject" title="Set up a connection and jobs"
              description="Connect your account and add jobs to get started" :editMode="false">
            </ConnectionComponent>

            <ul class="ms-auto list-unstyled">
              <li class="border mt-3 rounded p-2 no-arrow" style="background-color: #f9f9f9">
                <p>Extraction Information</p>
                <ul class="list-unstyled ms-2 me-2 overflow-auto" style="height: 200px">
                  <li class="border rounded no-arrow mt-1" v-for="(statusMessage, index) in statusMessages"
                    :key="index">
                    <span class="fw-bold">Job name: </span>{{ statusMessage.job }} -
                    <span class="fw-bold">Table: </span>{{ statusMessage.task }} -
                    <span class="fw-bold">Status: </span>{{ statusMessage.status }} -
                    <span v-if="statusMessage.info" class="fw-bold">Additional info: </span>{{ statusMessage.info }}
                  </li>
                </ul>
              </li>
              <li class="border mt-3 rounded p-2 no-arrow" style="background-color: #f9f9f9" v-if="!project.connection ||
                !project.connection.connectionEstablished
              ">
                <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                  <span class="m-0 fw-bold text-gray-500" v-if="!project.connection">
                    Add your jobs
                  </span>
                </div>
              </li>
              <li class="border mt-3 rounded p-2 no-arrow" v-else>
                <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                  <span class="m-0 fw-bold text-gray-800"> </span>
                  <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-sm shadow-sm ms-1"
                    data-bs-toggle="modal" data-bs-target="#pickJobFromLib"><i class="fa-solid fa-plus"></i> Add job</a>
                </div>
              </li>
              <li class="border mt-3 rounded p-2 no-arrow" v-if="project.jobs.length > 0">
                <span class="text-gray-500">Added jobs
                  <span class="text-success" style="font-weight: 700">
                    {{ project.jobs.length }}
                  </span></span>
                <ul class="ms-auto list-unstyled">
                  <li class="border mt-3 rounded p-2 no-arrow" v-for="(job, index) in project.jobs" :key="index">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                      <span class="m-0 fw-bold text-primary">
                        <div>
                          <span class="text-gray-800">
                            Extract:
                            <input type="checkbox" class="right" name="jobs" v-model="jobIds" :value="job.id" />
                          </span>
                        </div>
                        <span class="text-primary">{{ job.name }} </span>
                        <span class="ms-3"><i class="fa-solid fa-server"></i>
                          {{ job.allFields }} |
                        </span>
                        <span class="ms-1"><i class="fa-solid fa-filter"></i>
                          {{ job.allWhereClauses }} |
                        </span>
                        <span class="ms-1"><i class="fa fa-light fa-network-wired"></i>{{ job.allJoinClauses }}
                        </span>
                        <span v-if="job.status == 'EXTRACTION_COMPLETED'" href="#"
                          class="ms-2 d-none d-sm-inline-block shadow-sm badge text-bg-info p-2">{{ job.status }}
                        </span>
                        <span v-else-if="job.status == 'EXTRACTION_NEW'" href="#"
                          class="ms-2 d-none d-sm-inline-block shadow-sm badge text-bg-success p-2">{{ job.status }}
                        </span>
                        <span v-else-if="job.status == 'EXTRACTION_PROGRESSING'" href="#"
                          class="ms-2 d-none d-sm-inline-block shadow-sm badge text-bg-warning p-2">{{ job.status }}
                        </span>
                        <span v-else-if="job.status == 'EXTRACTION_ERROR'" href="#"
                          class="ms-2 d-none d-sm-inline-block shadow-sm badge text-bg-danger p-2">{{ job.status }}
                        </span>
                        <span v-else class="ms-2 d-none d-sm-inline-block shadow-sm badge text-bg-warning p-2">{{
                          job.status
                        }}
                        </span>
                        <a href="#" class="ms-1" v-on:click.prevent="getProject()"><i
                            class="fa-solid fa-arrows-rotate"></i></a>
                      </span>
                      <ul class="navbar-nav ms-auto">
                        <li class="nav-item dropdown no-arrow">
                          <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <h6><i class="fa-solid fa-ellipsis"></i></h6>
                          </a>
                          <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                            aria-labelledby="projectDropdown">
                            <a href="#" class="dropdown-item text-danger" v-if="!job.calendar.isActive"
                              data-bs-toggle="modal" data-bs-target="#removeJobModal" v-on:click="selectJob(job.id)"><i
                                class="fa-solid fa-trash-can"></i> Remove
                              job</a>
                            <a href="#" class="dropdown-item text-warning" v-if="!job.calendar.isActive"
                              v-on:click="activateJob(job.id)"><i class="fa-solid fa-trash-can"></i> Activate
                              job</a>
                            <a href="#" class="dropdown-item text-warning" v-if="job.calendar.isActive"
                              v-on:click="deactivateJob(job.id)"><i class="fa-solid fa-trash-can"></i> Deactivate
                              job</a>
                            <router-link :to="{
                              name: 'ProjectJobEdit',
                              params: { id: project.id, jobId: job.id },
                            }" class="dropdown-item text-warning" v-if="!job.calendar.isActive"><i
                                class="fa fa-duotone fa-pen-to-square"></i>
                              Edit job
                            </router-link>
                            <a href="#" class="dropdown-item text-primary" v-on:click="copyToTemplate(job.id)"><i
                                class="fa fa-regular fa-copy"></i> Copy to
                              template</a>
                            <router-link :to="{
                              name: 'ProjectJobFiles',
                              params: { jobId: job.id },
                            }" class="dropdown-item text-primary" v-if="job.status == 'EXTRACTION_COMPLETED'"><i
                                class="fa fa-duotone fa-pen-to-square"></i>
                              Files
                            </router-link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <!-- Pick from library or create from scratch-->
        <div class="modal fade" id="pickJobFromLib" tabindex="-1" role="dialog" aria-labelledby="pickJobFromLib"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="min-width: 400px">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Select your integration
                </h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <a href="#" v-on:click="getTemplates()"><i class="fa-regular fa-folder-open"
                        style="font-size: 100px"></i>
                      <p>Select from templates</p>
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <a href="#" v-on:click="createCustomJobs(projectId)" data-bs-dismiss="modal"><i
                        class="fa-solid fa-plus" style="font-size: 100px"></i>
                      <p>Create custom jobs</p>
                    </a>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="list-group" v-if="filteredTemplates.length > 0">
                      <div v-for="(template, index) in filteredTemplates" :key="index">
                        <input type="radio" :value="template.id" v-model="selectedTemplateId" name="flexRadioDefault"
                          :id="'flexRadioDefault2' + template.id" />
                        <label :for="'flexRadioDefault2' + template.id" class="list-group-item">
                          {{ template.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="row">
                  <a v-if="filteredTemplates.length > 0" class="btn btn-primary" data-bs-dismiss="modal"
                    v-on:click="jobFromTemplate()">Select</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="removeJobModal" tabindex="-1" role="dialog" aria-labelledby="removeJobModal"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h6>Are you sure you want to remove job?</h6>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                </button>
              </div>
              <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                  Cancel
                </button>
                <a class="btn btn-primary" data-bs-dismiss="modal" v-on:click="removeJob()">Remove</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Update project Modal-->
        <div class="modal fade" id="updateProjectModal" tabindex="-1" role="dialog" aria-labelledby="updateProjectModal"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="updateProjectModalLabel">
                  Update project
                </h5>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                </button>
              </div>
              <div class="modal-body">
                <form class="">
                  <div class="input-group">
                    <div style="min-width: 100px">Name:</div>
                    <input type="text" class="form-control bg-light small" v-model="project.name"
                      :class="{ 'border border-danger': projectNameError }" placeholder="Project name" id="projectName"
                      aria-label="Update" aria-describedby="basic-addon2" />
                  </div>
                  <div class="input-group mt-2">
                    <span style="min-width: 100px">Date start:</span>
                    <datepicker :class="{ 'border border-danger': startDateError }" v-model="project.dateStart">
                    </datepicker>
                  </div>
                  <div class="input-group mt-2">
                    <span style="min-width: 100px">Date end:</span>
                    <datepicker :class="{ 'border border-danger': endDateError }" v-model="project.dateEnd">
                    </datepicker>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                  Cancel
                </button>
                <a class="btn btn-warning" data-bs-dismiss="modal" @click="updateProject()">Update</a>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="removeProjectModal" tabindex="-1" role="dialog" aria-labelledby="removeProjectModal"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h6>Are you sure you want to remove project?</h6>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                </button>
              </div>
              <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                  Cancel
                </button>
                <a class="btn btn-primary" data-bs-dismiss="modal" v-on:click="removeProject()">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import { useRoute } from "vue-router";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Datepicker from "vuejs3-datepicker";
import TemplatesService from "../services/templatesService";
import ConnectionComponent from "@/components/ConnectionComponent.vue";

export default {
  name: "ProjectDetailsView",
  data: () => {
    return {
      project: {
        id: null,
        createdBy: {},
        tenant: {},
        jobs: [],
      },
      projectId: null,
      loading: true,
      selectedJob: null,
      startDate: null,
      endDate: null,
      templateId: null,
      templates: [],
      filteredTemplates: [],
      selectedTemplateId: null,
      projectNameError: false,
      startDateError: false,
      endDateError: false,
      jobIds: [],
      statusMessages: [],
    };
  },
  components: {
    LoadingIndicator,
    Datepicker,
    ConnectionComponent,
  },
  computed: {
    hasMarkedForExtraction() {
      return this.project.jobs.some(job => job.markedForExtraction === true);
    }
  },
  created() {
    const route = useRoute();
    this.projectId = route.params.id;

    this.getProject();

    this.$socket.invoke('AddToGroup', this.projectId).then(() => {
      const $toast = useToast();
      $toast.success(
        "Tracking project jobs"
      );
    })

    this.$socket.on("ProjectJobExtractionStatus", (data) => {
      let job = this.project.jobs.find((x) => x.id == data.id);
      if (job) {
        job.status = data.status;
      }
    });

    this.$socket.on("JobExtractionInfo", (data) => {
      console.log(data);
      this.statusMessages.unshift(data);
    });
  },
  watch: {
    "project.name": function (newTerm) {
      if (!newTerm) {
        this.projectNameError = true;
      } else {
        this.projectNameError = false;
      }
    },
    startDate(newTerm) {
      if (!newTerm) {
        this.startDateError = true;
      } else {
        this.startDateError = false;
      }
    },
    endDate(newTerm) {
      if (!newTerm) {
        this.endDateError = true;
      } else {
        this.endDateError = false;
      }
    },
  },
  methods: {
    getProject() {
      this.loading = true;
      ProjectsService.getProject(this.projectId).then((project) => {
        this.project = project;
        this.startDate = Date.parse(this.project.dateStart);
        this.endDate = Date.parse(this.project.dateEnd);

        this.loading = false;
      });
    },
    createCustomJobs(projectId) {
      this.$router.push({ name: "ProjectJobs", params: { id: projectId } });
    },
    removeProject() {
      ProjectsService.deleteProject(this.projectId)
        .then(() => {
          this.$router.push({ name: "Projects" });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          const $toast = useToast();
          $toast.error(
            "There was an error, please try again later or contact support"
          );
        });
    },
    selectJob(id) {
      this.selectedJob = id;
    },
    removeJob() {
      this.loading = true;
      ProjectsService.removeJob(this.selectedJob)
        .then(() => {
          this.getProject();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          const $toast = useToast();
          $toast.error(
            "There was an error, please try again later or contact support"
          );
        });
    },
    updateProject() {
      this.loading = true;

      if (!this.validate()) {
        this.loading = false;
        return;
      }

      ProjectsService.updateProject(this.projectId, this.project)
        .then(() => {
          const $toast = useToast();
          $toast.success("Project is updated");
          this.getProject();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          const $toast = useToast();
          $toast.error(
            "There was an error, please try again later or contact support"
          );

        });
    },
    validate() {
      let isOk = true;

      if (!this.project.name) {
        this.projectNameError = true;
        isOk = false;
      }

      if (!this.project.dateStart) {
        this.startDateError = true;
        isOk = false;
      }

      if (!this.project.dateEnd) {
        this.endDateError = true;
        isOk = false;
      }

      if (!isOk) {
        const $toast = useToast();
        $toast.error("Please fill in required fields");
      }

      return isOk;
    },
    extract() {
      if (this.jobIds.length < 1) {
        const $toast = useToast();
        $toast.error("Please select at least one job");

        return;
      }

      let extractionData = {
        jobIds: this.jobIds
      };

      const $toast = useToast();
      $toast.success("Project is extracting");

      ProjectsService.extractProject(this.projectId, extractionData).then(() => {
        this.getProject();
      });
    },
    stopExtract() {
      if (this.jobIds.length < 1) {
        const $toast = useToast();
        $toast.error("Please select at least one job");

        return;
      }

      let extractionData = {
        jobIds: this.jobIds
      };

      const $toast = useToast();
      $toast.warning("Stopped extracting project");

      ProjectsService.stopExtractProject(this.projectId, extractionData).then(() => {
        this.getProject();
      });
    },
    copyProject() {
      ProjectsService.copyProject(this.projectId)
        .then(() => {
          const $toast = useToast();
          $toast.success("Project is copied");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          const $toast = useToast();
          $toast.error(
            "There was an error, please try again later or contact support"
          );
        });
    },
    copyToTemplate(jobId) {
      this.loading = true;
      TemplatesService.copyFromJob(jobId)
        .then(() => {
          const $toast = useToast();
          $toast.success("Job is copied to template");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          const $toast = useToast();
          $toast.error(
            "There was an error, please try again later or contact support"
          );
        });
    },
    getTemplates() {
      this.loading = true;
      TemplatesService.getTemplates(false).then((templates) => {
        this.templates = templates;
        this.filteredTemplates = this.templates;
        this.loading = false;
      });
    },
    selectTemplate(id) {
      this.templateId = id;
    },
    jobFromTemplate() {
      this.loading = true;
      ProjectsService.createJobFromTemplate(
        this.projectId,
        this.selectedTemplateId
      )
        .then(() => {
          const $toast = useToast();
          $toast.success("Job is copied from template");
          this.getProject();
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.message);
          const $toast = useToast();
          $toast.error(
            "There was an error, please try again later or contact support"
          );
        });
    },
    activateJob(id) {
      this.loading = true;
      ProjectsService.activateJob(id)
        .then(() => {
          const $toast = useToast();
          $toast.success("Job is activated");
          this.getProject();
        })
        .catch((error) => {
          this.loading = false;
          const $toast = useToast();
          $toast.error(error.message);
        });
    },
    deactivateJob(id) {
      this.loading = true;
      ProjectsService.deactivateJob(id)
        .then(() => {
          const $toast = useToast();
          $toast.success("Job is deactivated");
          this.getProject();
        })
        .catch((error) => {
          this.loading = false;
          const $toast = useToast();
          $toast.error(error.message);
        });
    },
  },
};
</script>