<template>
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row" style="min-height:100px;">
                            <div class="col-lg-5">
                                <img src="../assets/images/huro_data_icon.svg" />
                                <div class="d-flex align-items-center">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h1 text-gray-900 mb-4">Sign in</h1>
                                            <h6 class="h6 text-gray-900 mb-4">Sign in with your company email address
                                            </h6>
                                        </div>
                                        <form class="user">
                                            <div class="form-floating mb-3">
                                                <input type="email" v-model="user.username"
                                                    :class="{ 'border border-danger': usernameError }"
                                                    class="form-control form-control-user" id="exampleInputEmail"
                                                    aria-describedby="emailHelp" placeholder="Enter Email Address..."
                                                    autocomplete="username">
                                                    <label for="exampleInputEmail">Enter Email Address...</label>
                                            </div>
                                            <div class="form-floating mb-3">
                                                <input type="password" v-model="user.password"
                                                    :class="{ 'border border-danger': passwordError }"
                                                    class="form-control form-control-user" id="exampleInputPassword"
                                                    placeholder="Password" autocomplete="current-password">
                                                    <label for="exampleInputPassword">Password</label>
                                            </div>
                                            <div class="mb-3">
                                                <router-link active-class="none" exact-active-class="none"
                                                    to="/forgot-password">
                                                    Forgot password?
                                                </router-link>
                                            </div>
                                            <a href="#" class="btn btn-primary btn-user btn-block" @click="login(a)">
                                                Sign in
                                            </a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 d-none d-lg-block" style="max-height: 600px;">
                                <img class="login-image" src="../assets/images/signing.jpeg" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
// @ is an alias to /src
import { authStore } from './../stores/authStore'
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';

export default {
    name: 'LoginView',
    data() {
        return {
            user: {
                username: null,
                password: null
            },
            usernameError: false,
            passwordError: false
        }
    },
    watch: {
        'user.username': function (newTerm) {
            if (!newTerm) {
                this.usernameError = true;
            } else {
                this.usernameError = false;
            }
        },
        'user.password': function (newTerm) {
            if (!newTerm) {
                this.passwordError = true;
            } else {
                this.passwordError = false;
            }
        },
    },
    methods: {
        login() {

            if (!this.validate()) {
                return;
            }

            const authStoreInstance = authStore();
            authStoreInstance.login(this.user).then(() => {
                this.$router.push("projects");
            })
                .catch(() => {
                    this.loading = false;
                    const $toast = useToast();
                    $toast.error('Incorrect username or password!');
                })
        },
        validate() {
            let isOk = true;

            if (!this.user.username) {
                this.usernameError = true;
                isOk = false;
            }

            if (!this.user.password) {
                this.passwordError = true;
                isOk = false;
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        }
    }
}
</script>
