import axios from "axios";

axios.defaults.baseURL = "https://api-dev.hurodata.com/api/";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("access_token");
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

const ApiService = {
  setHeader() {
    const access_token = JSON.parse(localStorage.getItem("access_token") || "{}");

    if (access_token) {
      axios.defaults.headers.Authorization = `Bearer ${access_token}`;
    } else {
      axios.defaults.headers.common.Authorization = "";
    }
  },

  get(resource) {
    this.setHeader();
    return axios.get(resource);
  },

  post(resource, data) {
    this.setHeader();
    return axios.post(resource, data);
  },

  put(resource, data) {
    this.setHeader();
    return axios.put(resource, data);
  },

  delete(resource) {
    this.setHeader();
    return axios.delete(resource);
  },

  customRequest(data) {
    this.setHeader();
    return axios(data);
  },

  download(resource) {
    this.setHeader();
    return axios.get(resource, { responseType: 'blob' });
  },

  upload(resource, data) {
    this.setHeader();
    return axios.post(resource, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default ApiService;
