<template>
  <div class="users scrollable no-show-scroll">
    <LoadingIndicator v-if="loading"></LoadingIndicator>
    <HeadingComponent title="Users" description="Add users to existing tenants" action="Create a new user"
      action-modal="#newUserModal" action-enabled="true"> </HeadingComponent>

    <div class="modal fade" id="newUserModal" tabindex="-1" role="dialog" aria-labelledby="newUserModal"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create a user</h5>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

            </button>
          </div>
          <div class="modal-body">
            <form class="row user mt-5 g-1">
              <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                <input type="text" v-model="userDto.firstName" class="form-control"
                  :class="{ 'border border-danger': firstNameError }" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="First name" id="firstName-new" placeholder="First name">
                <label for="firstName-new">First name</label>
              </div>
              <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                <input type="text" v-model="userDto.lastName" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Last name" class="form-control" id="lastName-new"
                  :class="{ 'border border-danger': lastNameError }" placeholder="Last name">
                <label for="lastName-new">Last name</label>
              </div>
              <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                <input type="text" v-model="userDto.phoneNumber" class="form-control" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Phone number" id="phoneNumber-new" placeholder="Phone number">
                <label for="phoneNumber-new">Phone number</label>
              </div>
              <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                <input type="text" v-model="userDto.email" class="form-control" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="User email" id="email-new"
                  :class="{ 'border border-danger': emailError }" placeholder="User email">
                <label for="email-new">Email</label>
              </div>
              <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                <select v-model="userDto.tenantId" class="form-select" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Client" id="tenant-new" placeholder="Client"
                  :class="{ 'border border-danger': tenantIdError }">
                  <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                </select>
                <label for="tenant-new">Client</label>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
              Cancel
            </button>
            <a class="btn btn-primary" data-bs-dismiss="modal" @click="createUser()">Create</a>
          </div>
        </div>
      </div>
    </div>

    <ContentWrapper>

      <SearchBarComponent title="All users"></SearchBarComponent>

      <div class="row me-5 mt-4">
        <div class="col-xl-3 col-md-4 mb-3" v-for="user in filteredUsers" :key="user.id">
          <div class="card shadow-sm" style="min-height:260px;">
            <div class="card-header bg-success" :class="{ 'bg-warning': !user.isActive }">
              <div class="d-sm-flex align-items-center justify-content-between">
                <h6 class="m-0 fw-bold text-white">
                  {{ user.firstName }} {{ user.lastName }}
                </h6>
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item dropdown no-arrow">
                    <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown"
                      role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <h6><i class="fa-solid fa-ellipsis text-light"></i></h6>
                    </a>
                    <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                      aria-labelledby="projectDropdown">
                      <router-link :to="{
                        name: 'User',
                        params: { id: user.id }
                      }" class="dropdown-item text-warning">
                        <i class="fa fa-duotone fa-pen-to-square"></i>
                        Edit user
                      </router-link>
                      <a class="dropdown-item text-danger" v-if="user.isActive" href="#" data-bs-dismiss="modal"
                        v-on:click="selectUser(user.id)" data-bs-toggle="modal" data-bs-target="#deactivateUserModal">
                        <i class="fa-solid fa-trash-can"></i>
                        Deactivate user
                      </a>
                      <a class="dropdown-item text-success" v-else href="#" data-bs-dismiss="modal"
                        v-on:click="activateUser(user.id)">
                        <i class="fa-solid fa-rotate-left"></i>
                        Activate user
                      </a>
                      <a class="dropdown-item text-warning" href="#" v-on:click="changeUserPassword(user.id)">
                        <i class="fa-solid fa-key"></i>
                        Reset password
                      </a>
                      <a class="dropdown-item text-danger" v-if="!user.isActive && isSuperAdmin" href="#"
                        data-bs-dismiss="modal" v-on:click="selectUser(user.id)" data-bs-toggle="modal"
                        data-bs-target="#deleteUserModal">
                        <i class="fa-solid fa-trash-can"></i>
                        Delete user
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <h1 class="h5">{{ user.email }}</h1>
              <h4 class="h6">
                <i class="fa-solid fa-clipboard-list"></i> {{ user.tenantName }}
              </h4>
            </div>
            <div class="card-footer">
              <div class="d-sm-flex align-items-center justify-content-between">
                <span>Last login date: {{ user.lastLoginDate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete user modal -->
      <div class="modal fade" id="deleteUserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">User delete</h5>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

              </button>
            </div>
            <div class="modal-body">
              Are you sure you want to delete user?
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <a class="btn btn-primary" data-bs-dismiss="modal" @click="deleteUser()">Delete</a>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="deactivateUserModal" tabindex="-1" role="dialog" aria-labelledby="deactivateUserModal"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6>Are you sure you want to deactivate user?</h6>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

              </button>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <a class="btn btn-primary" data-bs-dismiss="modal" v-on:click="deactivateUser()">Deactivate</a>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
// @ is an alias to /src
import UsersService from "../services/usersService";
import ManagementService from "../services/managementService";
import ClientsService from "../services/clientsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import HeadingComponent from '../components/HeadingComponent.vue'
import SearchBarComponent from '../components/SearchBarComponent.vue'
import ContentWrapper from '../components/ContentWrapper.vue'

export default {
  name: 'UsersView',
  components: { LoadingIndicator, HeadingComponent, SearchBarComponent, ContentWrapper },
  data: () => {
    return {
      loading: true,
      users: [],
      filteredUsers: [],
      clients: [],
      searchTerm: null,
      userDto: {
        firstName: null,
        lastName: null,
        email: null,
        tenantId: null,
        phoneNumber: null
      },
      sortText: '<i class="fa-sharp fa-solid fa-arrow-up"></i>',
      orderBy: false,
      selectedUserId: null,
      selectedRoleId: null,
      firstNameError: false,
      lastNameError: false,
      tenantIdError: false,
      emailError: false,
      isSuperAdmin: false
    }
  },
  computed: {
  },
  created() {
    this.getUsers();
    this.getClients();

    UsersService.getCurrent().then(
      (user) => {
        this.isSuperAdmin = user?.roles?.filter(x => x == "SuperAdmin").length > 0
      }
    );
  },
  watch: {
    searchTerm(newTerm, oldTerm) {
      if (newTerm != oldTerm) {
        this.filteredUsers = this.users.filter(x => x.firstName.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1 || x.lastName.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1);
      } else {
        this.filteredUsers = this.users
      }
    },
    'userDto.firstName': function (newTerm) {
      if (!newTerm) {
        this.firstNameError = true;
      } else {
        this.firstNameError = false;
      }
    },
    'userDto.lastName': function (newTerm) {
      if (!newTerm) {
        this.lastNameError = true;
      } else {
        this.lastNameError = false;
      }
    },
    'userDto.email': function (newTerm) {
      if (!newTerm) {
        this.emailError = true;
      } else {
        this.emailError = false;
      }
    },
    'userDto.tenantId': function (newTerm) {
      if (!newTerm) {
        this.tenantIdError = true;
      } else {
        this.tenantIdError = false;
      }
    },
  },
  methods: {
    getUsers() {
      UsersService.getUsers(this.orderBy).then((users) => {
        this.users = users;
        this.filteredUsers = users;
        this.loading = false;
      });
    },
    sort() {
      this.orderBy = !this.orderBy;
      this.sortText = this.orderBy ? '<i class="fa-sharp fa-solid fa-arrow-down"></i>' : '<i class="fa-sharp fa-solid fa-arrow-up"></i>';
      this.getUsers();
    },
    getClients() {
      ClientsService.getClients(this.orderBy).then((clients) => {
        this.clients = clients;
        this.loading = false;
      });
    },
    selectUser(id) {
      this.selectedUserId = id;
    },
    deactivateUser() {
      this.loading = true;
      UsersService.deleteUser(this.selectedUserId).then(() => {
        const $toast = useToast();
        $toast.success('User deactivated!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    activateUser(id) {
      this.loading = true;
      UsersService.activateUser(id).then(() => {
        const $toast = useToast();
        $toast.success('User activated!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    deleteUser() {
      this.loading = true;
      ManagementService.deleteUser(this.selectedUserId).then(() => {
        const $toast = useToast();
        $toast.success('User deleted!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    changeUserPassword(userId) {
      this.loading = true;
      UsersService.changeUserPassword(userId).then(() => {
        const $toast = useToast();
        $toast.success('Password changed successfully!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    createUser() {
      this.loading = true;

      if (!this.validateCreateUser()) {
        this.loading = false;
        return;
      }

      UsersService.createUser(this.userDto).then(() => {
        const $toast = useToast();
        $toast.success('User created!');
        this.userDto = {
          firstName: null,
          lastName: null,
          email: null,
          tenantId: null,
          phoneNumber: null
        };
        this.getUsers();
      })
        .catch(() => {
          this.loading = false;
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    validateCreateUser() {
      let isOk = true;

      if (!this.userDto.firstName) {
        this.firstNameError = true;
        isOk = false;
      }

      if (!this.userDto.lastName) {
        this.lastNameError = true;
        isOk = false;
      }

      if (!this.userDto.email) {
        this.emailError = true;
        isOk = false;
      }

      if (!this.userDto.tenantId) {
        this.tenantIdError = true;
        isOk = false;
      }

      if (!isOk) {
        const $toast = useToast();
        $toast.error('Please fill in required fields');
      }

      // eslint-disable-next-line
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.userDto.email)) {
        const $toast = useToast();
        $toast.error('Not valid email address');

        isOk = false;
      }

      return isOk;
    },
  }
}
</script>