<template>
    <div class="project-details scrollable no-show-scroll" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600">
                Project job files
            </h6>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-5 offset-lg-5 col-sm-12 mx-auto  mb-4 ms-5 mt-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <div class="d-sm-flex align-items-center justify-content-between">
                            <h1 class="m-0 fw-bold h4">
                                Project job files extracted
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm mt-3">
                    <div class="card-body">
                        <div id="accordion">
                            <div class="card" v-for="table in tables" v-bind:key="table.tableName">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-bs-toggle="collapse"
                                            :data-bs-target='"#acc" + table.tableName' aria-expanded="true"
                                            aria-controls="collapseOne">
                                            {{ table.tableName }}
                                        </button>
                                    </h5>
                                </div>

                                <div :id='"acc" + table.tableName' class="collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordion">
                                    <div class="card-body">
                                        <ul class="list-unstyled text-start ms-auto">
                                            <li v-for="file in table.files" v-bind:key="file.id">
                                                <a href="#" v-on:click="downloadFile(file.id, file.fileName)">{{
                                                    file.fileName }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService from "../services/projectsService";
import { useRoute } from 'vue-router'
import LoadingIndicator from "../components/LoadingIndicator.vue";
import StorageViewerService from "../services/storageViewerService";

export default {
    name: "ProjectJobFilesView",
    data: () => {
        return {
            loading: false,
            tables: []
        };
    },
    components: {
        LoadingIndicator,
    },
    created() {
        const route = useRoute();
        this.jobId = route.params.jobId;

        this.getFiles();
    },
    methods: {
        getFiles() {
            this.loading = true;
            ProjectsService.getProjectJobFiles(this.jobId).then(
                (tables) => {
                    this.tables = tables;
                    this.loading = false;
                });
        },
        downloadFile(id, fileName) {
            this.loading = true;
            StorageViewerService.downloadFile(id).then(
                (response) => {
                    this.loading = false;
                    const url = window.URL.createObjectURL(new Blob([response]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', fileName)
                    document.body.appendChild(link)
                    link.click()
                });
        }
    },
};
</script>