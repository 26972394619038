<template>
    <div class="template">
        <LoadingIndicator v-if="loading"></LoadingIndicator>
        <HeadingComponent title="Templates" description="All your templates" action="Create a new template"
            action-enabled="false" action-modal="">
        </HeadingComponent>

        <ContentWrapper>
            <div class="row me-5 mt-4">
                <div class="col-xl-4 col-md-6 mb-3">

                    <div class="card shadow-sm">
                        <div class="card-header">
                            <div class="d-sm-flex align-items-center justify-content-between"
                                style="background-color: #fff">
                            </div>
                        </div>
                        <div class="card-body" style="min-height: 100px; color: #7b7b7b">
                            <div class="mb-3">
                                <label for="formFile" class="form-label">Upload logo</label>
                                <input class="form-control" type="file" id="formFile" v-on:change="handleFileChange($event)">
                            </div>
                        </div>
                        <div class="card-footer">
                            <h4 class="h6 text-info">
                                <button class="btn btn-sm btn-primary ms-2" v-on:click="uploadLogo()">Upload</button>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
// @ is an alias to /src
import LoadingIndicator from "../components/LoadingIndicator.vue";
import HeadingComponent from "../components/HeadingComponent.vue";
import ClientsService from "../services/clientsService";
import ContentWrapper from "../components/ContentWrapper.vue";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

export default {
    name: "TenantSettings",
    data: () => {
        return {
            settings: null,
            name: null,
            description: null,
            loading: true,
            logoFile: null,
            logoWidth: null,
            logoHeight: null
        };
    },
    components: {
        LoadingIndicator,
        HeadingComponent,
        ContentWrapper,
    },
    created() {
        this.loading = false;
    },
    methods: {
        getSettings() {
            ClientsService.getSettings().then((settings) => {
                this.settings = settings;
                this.loading = false;
            });
        },
        handleFileChange(e) {
            this.logoFile = e.target.files[0];
        },
        async uploadLogo() {

            this.loading = true;

            if (!this.validateLogo()) {

                this.loading = false;
                return;
            }

            this.getUploadedFileDimensions(this.logoFile).then((res) => {
                if (!res) {

                    const $toast = useToast();
                    $toast.error("Image size is too big!");
                    this.loading = false;
                    return;
                }

                const formData = new FormData();
                formData.append('logo', this.logoFile);

                ClientsService.uploadLogo(formData)
                    .then(() => {
                        this.loading = false;
                        this.logoFile = null;
                        location.reload();
                    })
                    .catch((error) => {
                        this.loading = false;
                        console.log(error.message);
                        const $toast = useToast();
                        $toast.error(
                            "There was an error, please try again later or contact support"
                        );
                    });
            })
        },
        validateLogo() {

            const $toast = useToast();

            if (!this.logoFile || this.logoFile.type.indexOf('image/') !== 0) {

                $toast.error("File is not image!");

                return false;
            }

            if (this.logoFile.size > 102400) {

                $toast.error("File size is too big!");
                return false;
            }

            return true;
        },
        getUploadedFileDimensions: file => new Promise((resolve, reject) => {
            try {
                let img = new Image()

                img.onload = () => {
                    window.URL.revokeObjectURL(img.src)

                    if (img.naturalWidth > 100 || img.naturalHeight > 100) {
                        return resolve(false)
                    } else {
                        return resolve(true)
                    }
                }

                img.src = window.URL.createObjectURL(file)
            } catch (exception) {
                return reject(exception)
            }
        }),
        getLogo() {
            ClientsService.getLogo()
                .then(() => {
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error.message);
                    const $toast = useToast();
                    $toast.error(
                        "There was an error, please try again later or contact support"
                    );
                });
        },
    },
};
</script>
