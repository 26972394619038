<template>
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row" style="min-height:100px;">
                            <div class="col-lg-5">
                                <img src="../assets/images/huro_data_icon.svg" />
                                <div class="d-flex align-items-center">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h1 class="h1 text-gray-900 mb-4">Reset password</h1>
                                        </div>
                                        <form class="user">
                                            <div class="form-floating mb-3">
                                                <input type="password" v-model="data.newPassword"
                                                    :class="{ 'border border-danger': newPasswordError }"
                                                    class="form-control form-control-user" id="exampleInputEmail"
                                                    aria-describedby="emailHelp" placeholder="Enter new password...">
                                                    <label for="exampleInputEmail">Enter new password...</label>
                                            </div>
                                            <a href="#" class="btn btn-primary btn-user btn-block" @click="reset()">
                                                Reset
                                            </a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 d-none d-lg-block" style="max-height: 600px;">
                                <img class="login-image" src="../assets/images/signing.jpeg" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
// @ is an alias to /src
import UsersService from "../services/usersService";
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';

export default {
    name: 'LoginView',
    data() {
        return {
            loading: false,
            data: {
                newPassword: null,
                token: null,
                email: null
            },
            newPasswordError: false
        }
    },
    methods: {
        reset() {

            if (!this.data.newPassword) {

                this.newPasswordError = true;
                return;
            }
            this.loading = true;

            const searchURL = new URL(window.location);
            const urlParams = new URLSearchParams(searchURL.search);
            const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
            const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}

            function paramsToObject(entries) {
                const result = {}
                for (const [key, value] of entries) { // each 'entry' yis a [key, value] tupple
                    result[key] = value;
                }
                return result;
            }

            this.data.email = params.email;
            this.data.token = params.token;

            UsersService.resetPassword(this.data).then(() => {
                const $toast = useToast();
                $toast.success('Password succesfully changed!');
                window.location = "/login";
            })
                .catch((e) => {
                    this.loading = false;
                    const $toast = useToast();
                    $toast.error(e.message);
                })
        },
    }
}
</script>
