import axios from "axios";

const API_URL = "https://api-dev.hurodata.com/api/";

const axiosInstance = axios.create({
  baseURL: API_URL,
  //withCredentials: true,
});

axiosInstance.defaults.headers["Content-Type"] = "application/json";

class AuthService {
  login(user) {
    return axiosInstance
      .post(API_URL + "users/login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response) {
          localStorage.setItem("access_token", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("tenantId");
  }

  register(user) {
    return axios.post(API_URL + "signup", {
      username: user.email,
      email: user.email,
      password: user.password,
      passwordConfirm: user.passwordConfirm,
      firtname: user.firstname,
      lastname: user.lastname,
    });
  }

  changeToken(token) {
    localStorage.setItem("access_token", JSON.stringify(token));
  }
}

export default new AuthService();
