<template>
    <!-- Page Heading -->
    <div class="row mb-6" style="text-align: left">
        <div class="col-xl-9 col-md-9 col-sm-9">
            <ul class="nav navbar navbar-left d-flex d-inline-flex shadow p-3 rounded-3">
                <li class="nav-item d-inline-flex align-items-center me-2">
                    <a class="nav-link d-inline-flex" href="#">{{ title }}</a>
                </li>
                <li class="nav-item d-inline-flex align-items-center me-2">
                    <form
                        class="d-none d-sm-inline-block d-flex align-items-center me-auto ms-md-3 my-2 my-md-0 mw-200 navbar-search">
                        <div class="input-group">
                            <div class="form-floating">
                                <input type="text" class="form-control bg-light border-0 small" id="search"
                                    v-model="searchTerm" placeholder="Search for..." aria-label="Search"
                                    aria-describedby="basic-addon2" />
                                <label for="search">Search for...</label>
                            </div>
                            <button class="btn btn-primary" type="button" data-bs-toggle="tooltip" title="Search"
                                data-bs-placement="top">
                                <i class="fas fa-search fa-sm"></i>
                            </button>
                            <button class="btn btn-primary" data-bs-toggle="tooltip" title="Sort"
                                data-bs-placement="top" v-on:click="sort()" type="button">
                                <i class="fa fa-solid fa-sort"></i>
                            </button>
                        </div>
                    </form>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    props: ['title'],
    data: () => {
        return {
            searchTerm: null,
        };
    },
    methods: {
        sort() {
            this.$parent.$parent.sort();
        }
    },
    watch: {
        searchTerm(newTerm) {
            this.$parent.$parent.searchTerm = newTerm;
        }
    },
}
</script>