<template>
    <div class="template-details scrollable no-show-scroll">

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600">
                Templates / {{ template.name }}
            </h6>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-5 offset-lg-4 col-sm-12 mx-auto  mb-4 mt-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <div class="d-sm-flex align-items-center justify-content-between">
                            <h1 class="m-0 fw-bold">
                                {{ template.name }}
                            </h1>
                            <ul class="navbar-nav ms-auto" v-if="isAdmin">
                                <li class="nav-item dropdown no-arrow">
                                    <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#"
                                        id="projectDropdown" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                                        aria-labelledby="projectDropdown">
                                        <a class="dropdown-item text-warning" href="#" data-bs-dismiss="modal"
                                            data-bs-toggle="modal" data-bs-target="#updateTemplateModal">
                                            <i class="fa fa-duotone fa-pen-to-square"></i>
                                            Edit template
                                        </a>
                                        <a class="dropdown-item text-danger" href="#" data-bs-dismiss="modal"
                                            data-bs-toggle="modal" data-bs-target="#removeTemplateModal">
                                            <i class="fa-solid fa-trash-can"></i>
                                            Remove template
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Update project Modal-->
                    <div class="modal fade" id="updateTemplateModal" tabindex="-1" role="dialog"
                        aria-labelledby="updateTemplateModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="updateTemplateModalLabel">Update template</h5>
                                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form class="">
                                        <div class="form-floating">
                                            <input type="text" class="form-control bg-light border-0 small"
                                                v-model="template.name" placeholder="Template name"
                                                id="template-name-edit" aria-label="Update"
                                                aria-describedby="basic-addon2" />
                                            <label for="template-name-edit">Template name</label>
                                        </div>
                                        <div class="form-floating mt-2">
                                            <textarea type="text" class="form-control bg-light border-0" rows="15"
                                                style="height: 105px;" v-model="template.description"
                                                placeholder="Template description" aria-label="Update"
                                                aria-describedby="basic-addon2"
                                                id="connection-description-edit"></textarea>
                                            <label for="connection-description-edit">Template description</label>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal">
                                        Cancel
                                    </button>
                                    <a class="btn btn-warning btn-sm" data-bs-dismiss="modal"
                                        @click="updateTemplate()">Update</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="removeTemplateModal" tabindex="-1" role="dialog"
                        aria-labelledby="removeTemplateModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h6>Are you sure you want to remove template?</h6>
                                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                                    </button>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                                        Cancel
                                    </button>
                                    <a class="btn btn-primary" data-bs-dismiss="modal"
                                        v-on:click="removeTemplate()">Remove</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                <span>{{ template.description }}</span>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div id="accordionFinalized" class="col-lg-12 col-sm-12">
                                <h4 class="h4">Tasks</h4>
                                <div class="card" v-for="task in template.tasks" :key="task.id">
                                    <div class="card-header" :id='"headingOne" + task.table.name'>
                                        <h5 class="mb-0">
                                            <div class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded"
                                                data-bs-toggle="collapse" :data-bs-target='"#acc" + task.id'
                                                aria-expanded="true" aria-controls="collapseOne">
                                                <span class="m-0 fw-bold h6" style="min-width: 100px;">
                                                    {{ task.table.name }}
                                                </span>
                                            </div>
                                        </h5>
                                    </div>
                                    <div :id='"acc" + task.id' class="collapse" aria-labelledby="headingOne"
                                        data-parent="#accordionFinalized">
                                        <div class="card-body">
                                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" :id='"fields-tab" + task.id'
                                                        data-bs-toggle="tab" :href='"#fields" + task.id' role="tab"
                                                        aria-controls="home" aria-selected="true">Fields</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" :id='"joins-tab" + task.id' data-bs-toggle="tab"
                                                        :href='"#joins" + task.id' role="tab" aria-controls="profile"
                                                        aria-selected="false">Joins</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" :id='"filter-tab" + task.id'
                                                        data-bs-toggle="tab" :href='"#filter" + task.id' role="tab"
                                                        aria-controls="contact" aria-selected="false">Filters</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" :id='"datefields-tab" + task.id'
                                                        data-bs-toggle="tab" :href='"#datefields" + task.id' role="tab"
                                                        aria-controls="contact" aria-selected="false">Date fields</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" :id='"fields" + task.id'
                                                    role="tabpanel" aria-labelledby="fields-tab">
                                                    <div class="row mt-2">
                                                        <span class="badge text-bg-info badge-lg m-1 col-sm-1"
                                                            v-for="field in task.table.fields" :key="field.id">{{
                                                                field.name
                                                            }}</span>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" :id='"joins" + task.id' role="tabpanel"
                                                    aria-labelledby="joins-tab">
                                                    <div class="row mt-2" v-if="task.table.joinClauses.length > 0">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <table class="table table-striped table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Source field</th>
                                                                        <th>Action</th>
                                                                        <th>Destination table</th>
                                                                        <th>Destination field</th>
                                                                        <th>Operator</th>
                                                                        <th>Group</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(join, index) in task.table.joinClauses"
                                                                        :key="index">
                                                                        <td>{{ join.fieldSource }}</td>
                                                                        <td>{{ join.action }}</td>
                                                                        <td>{{ join.tableDestination }}</td>
                                                                        <td>{{ join.fieldDestination }}</td>
                                                                        <td>{{ join.operator }}</td>
                                                                        <td>{{ join.group }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" :id='"filter" + task.id' role="tabpanel"
                                                    aria-labelledby="filter-tab">
                                                    <div class="row mt-2" v-if="task.table.whereClauses.length > 0">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <table class="table table-striped table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Field</th>
                                                                        <th>Action</th>
                                                                        <th>Value</th>
                                                                        <th>Operator</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(where, index) in task.table.whereClauses"
                                                                        :key="index">
                                                                        <td>{{ where.field }}</td>
                                                                        <td>{{ where.action }}</td>
                                                                        <td>{{ where.value }}</td>
                                                                        <td>{{ where.operator }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" :id='"datefields" + task.id' role="tabpanel"
                                                    aria-labelledby="datefields-tab">
                                                    <div class="row mt-2">
                                                        <span class="badge text-bg-info badge-lg m-1 col-sm-1"
                                                            v-for="field in arrayOfDateFields(task.dateFields)"
                                                            :key="field">{{ field }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import TemplatesService from "../services/templatesService";
import { useRoute } from 'vue-router'
import LoadingIndicator from "../components/LoadingIndicator.vue";
import 'vue-toast-notification/dist/theme-sugar.css';
import { useToast } from 'vue-toast-notification';
import { authStore } from './../stores/authStore'

export default {
    name: "TemplateDetailsView",
    data: () => {
        return {
            template: {
            },
            templateId: null,
            loading: true,
            selectedJob: null,
            startDate: null,
            endDate: null
        };
    },
    components: {
        LoadingIndicator
    },
    created() {
        const route = useRoute();
        this.templateId = route.params.id;
        this.loading = true;
        this.getTemplate();
    },
    computed: {
        isAdmin: () => {
            const authStoreInstance = authStore();
            return authStoreInstance.getCurrentUser()?.user?.roles?.filter(x => x == "SuperAdmin" || x == "PartnerAdmin").length > 0
        }
    },
    methods: {
        arrayOfDateFields(dateFields) {
            return dateFields.split(",");
        },
        getTemplate() {
            TemplatesService.getTemplate(this.templateId).then(
                (template) => {
                    this.template = template;
                    this.loading = false;
                });
        },
        selectJob(id) {
            this.selectedJob = id;
        },
        updateTemplate() {
            this.loading = true;
            TemplatesService.updateTemplate(this.templateId, this.template).then(
                () => {
                    const $toast = useToast();
                    $toast.success('Template is updated');
                    this.getTemplate();
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        removeTemplate() {
            this.loading = true;
            TemplatesService.deleteTemplate(this.templateId).then(
                () => {
                    this.$router.push({ name: 'Templates' });
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        }
    },
};
</script>
