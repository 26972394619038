<template>
  <div class="users">
    <LoadingIndicator v-if="loading"></LoadingIndicator>

    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h6 class="h6 text-gray-600">
        Edit user / {{ userDto.firstName }} {{ userDto.lastName }}
      </h6>
    </div>

    <!-- Content Row -->
    <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
      <div class="col-lg-5 offset-lg-5 col-sm-12 mx-auto  mb-4 mt-4">
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-sm-flex align-items-center justify-content-between">
              <h1 class="m-0 fw-bold text-gray-800" style="color: #7b7b7b !important;">
                {{ userDto.firstName }} {{ userDto.lastName }}
              </h1>

              <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown no-arrow">
                  <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown" role="button"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <h6><i class="fa-solid fa-ellipsis"></i></h6>
                  </a>
                  <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                    aria-labelledby="projectDropdown">
                    <a class="dropdown-item text-danger" v-if="userDto.isActive" href="#" data-bs-dismiss="modal"
                      v-on:click="selectUser(user.id)" data-bs-toggle="modal" data-bs-target="#deactivateUserModal">
                      <i class="fa-solid fa-trash-can"></i>
                      Deactivate user
                    </a>
                    <a class="dropdown-item text-success" v-else href="#" data-bs-dismiss="modal"
                      v-on:click="activateUser(user.id)">
                      <i class="fa-solid fa-rotate-left"></i>
                      Activate user
                    </a>
                    <a class="dropdown-item text-warning" href="#" v-on:click="changeUserPassword(userDto.id)">
                      <i class="fa-solid fa-key"></i>
                      Reset password
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card shadow-sm mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-lg 12 col-md-12">
                <form class="row user mt-5 g-1">
                  <div class="form-floating mb-3 col-md-6 col-lg-6">
                    <input type="text" v-model="userDto.firstName" class="form-control"
                      :class="{ 'border border-danger': firstNameError }" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="First name" id="firstName-new" placeholder="First name">
                      <label class="text-gray-800" for="firstName-new">First name</label>                      
                  </div>
                  <div class="form-floating mb-3 col-md-6 col-lg-6">
                    <input type="text" v-model="userDto.lastName" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Last name" class="form-control" id="lastName-new"
                      :class="{ 'border border-danger': lastNameError }" placeholder="Last name">
                      <label class="text-gray-800" for="lastName-new">Last name</label>                      
                  </div>
                  <div class="form-floating mb-3 col-md-6 col-lg-6">
                    <input type="text" v-model="userDto.phoneNumber" class="form-control" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="Phone number" id="phoneNumber-new" placeholder="Phone number">
                      <label class="text-gray-800" for="phoneNumber-new">Phone number</label>                      
                  </div>
                  <div class="form-floating mb-3 col-md-6 col-lg-6">
                    <select v-model="userDto.tenantId" class="form-select" data-bs-toggle="tooltip"
                      data-bs-placement="top" title="Client" :class="{ 'border border-danger': tenantIdError }"
                      id="tenant-edit" placeholder="Client">
                      <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
                    </select>
                    <label class="text-gray-800" for="tenant-edit">Client</label>                    
                  </div>

                  <div class="mb-3">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                      <span class="m-0 fw-bold text-gray-800">
                      </span>
                      <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-sm shadow-sm ms-1"
                        @click="updateUser()" style="min-width: 74px;">
                        Save
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-sm mt-3">
          <div class="card-body">
            <div class="row border mt-3 ms-1 me-1 rounded p-2 no-arrow">
              <div class="col-lg 12 col-md-12">
                <span class="text-gray-500">Roles</span>
                <ul class="ms-auto list-unstyled">
                  <li class="border rounded ps-2 pe-2 mt-2 no-arrow" v-for="role in userDto.roles" :key="role.id">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                      <span class="m-0 fw-bold text-gray-800">{{ role.name }}</span>
                      <a class="btn btn-danger btn-sm mb-1 me-1" @click="removeRoleFromUser(role.id)"
                        style="min-width: 75px;">Remove</a>
                    </div>
                  </li>
                  <li class="border rounded ps-2 pe-2 mt-2 no-arrow">
                    <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                      <div class="form-floating g-1">
                      <select v-model="selectedRoleId" class="form-select form-select mb-2" style="max-width: 300px;"
                        data-bs-toggle="tooltip" data-bs-placement="top" title="Role" id="role-assign"
                        placeholder="Role">
                        <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                      </select>
                      <label class="text-gray-800" for="role-assign">Role</label>
                    </div>
                      <a class="btn btn-primary btn me-1 mb-2" style="min-width: 74px;"
                        @click="addRoleToUser()">Assign</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ContentWrapper>

      <div class="modal fade" id="deactivateUserModal" tabindex="-1" role="dialog" aria-labelledby="deactivateUserModal"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h6>Are you sure you want to deactivate user?</h6>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

              </button>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                Cancel
              </button>
              <a class="btn btn-primary" data-bs-dismiss="modal" v-on:click="deactivateUser()">Deactivate</a>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </div>
</template>

<script>
// @ is an alias to /src
import UsersService from "../services/usersService";
import ClientsService from "../services/clientsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ContentWrapper from '../components/ContentWrapper.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'UserView',
  components: { LoadingIndicator, ContentWrapper },
  data: () => {
    return {
      loading: true,
      clients: [],
      roles: [],
      userDto: {
        firstName: null,
        lastName: null,
        email: null,
        tenantId: null,
        phoneNumber: null,
        isActive: null
      },
      selectedUserDto: {
        firstName: null,
        lastName: null,
        email: null,
        tenantId: 0,
        roles: [],
        phoneNumber: null
      },
      selectedUserId: null,
      selectedRoleId: null,
      firstNameError: false,
      lastNameError: false,
      tenantIdError: false,
      emailError: false,
      userId: null
    }
  },
  computed: {
  },
  created() {

    const route = useRoute();
    this.userId = route.params.id;

    this.getUser();
    this.getRoles();
    this.getClients();
  },
  watch: {
    'userDto.firstName': function (newTerm) {
      if (!newTerm) {
        this.firstNameError = true;
      } else {
        this.firstNameError = false;
      }
    },
    'userDto.lastName': function (newTerm) {
      if (!newTerm) {
        this.lastNameError = true;
      } else {
        this.lastNameError = false;
      }
    },
    'userDto.email': function (newTerm) {
      if (!newTerm) {
        this.emailError = true;
      } else {
        this.emailError = false;
      }
    },
    'userDto.tenantId': function (newTerm) {
      if (!newTerm) {
        this.tenantIdError = true;
      } else {
        this.tenantIdError = false;
      }
    },
  },
  methods: {
    getUser() {
      UsersService.getUser(this.userId).then((user) => {
        this.userDto = user;
        this.loading = false;
      });
    },
    getRoles() {
      UsersService.getRoles().then((roles) => {
        this.roles = roles;
        this.loading = false;
      });
    },
    getClients() {
      ClientsService.getClients(true).then((clients) => {
        this.clients = clients;
        this.loading = false;
      });
    },
    validateEditUser() {
      let isOk = true;

      if (!this.userDto.firstName) {
        this.firstNameError = true;
        isOk = false;
      }

      if (!this.userDto.lastName) {
        this.lastNameError = true;
        isOk = false;
      }

      if (!this.userDto.tenantId) {
        this.tenantIdError = true;
        isOk = false;
      }

      if (!isOk) {
        const $toast = useToast();
        $toast.error('Please fill in required fields');
      }

      return isOk;
    },
    selectUser(id) {
      this.selectedUserId = id;
    },
    deactivateUser() {
      this.loading = true;
      UsersService.deleteUser(this.selectedUserId).then(() => {
        const $toast = useToast();
        $toast.success('User deactivated!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    activateUser(id) {
      this.loading = true;
      UsersService.activateUser(id).then(() => {
        const $toast = useToast();
        $toast.success('User activated!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    updateUser() {
      this.loading = true;

      if (!this.validateEditUser()) {
        this.loading = false;
        return;
      }

      UsersService.updateUser(this.userId, this.userDto).then(() => {
        const $toast = useToast();
        $toast.success('Update successful!');
        this.getUser();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    selectEditUser(id) {
      this.selectedUserDto = this.users.find(x => x.id == id);
    },
    addRoleToUser() {
      this.loading = true;
      UsersService.assignRoleToUser(this.userId, this.selectedRoleId).then(() => {
        const $toast = useToast();
        $toast.success('Role added successfully!');
        this.getUser();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    removeRoleFromUser(roleId) {
      this.loading = true;
      UsersService.removeRoleFromUser(this.userId, roleId).then(() => {
        const $toast = useToast();
        $toast.success('Role removed successfully!');
        this.getUser();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
    changeUserPassword(userId) {
      this.loading = true;
      UsersService.changeUserPassword(userId).then(() => {
        const $toast = useToast();
        $toast.success('Password changed successfully!');
        this.getUsers();
      })
        .catch(error => {
          this.loading = false;
          console.log(error.message)
          const $toast = useToast();
          $toast.error('There was an error, please try again later or contact support');
        })
    },
  }
}
</script>