<template>
    <h1 class="h4">{{ title }}</h1>
    <h4 class="h6">
        {{ description }}
    </h4>

    <ul class="ms-auto list-unstyled">
        <li class="border mt-3 rounded p-2 no-arrow">
            <div class="d-sm-flex align-items-center justify-content-between" v-if="!project.connection">
                <span class="m-0 fw-bold">
                    Connect your integration
                </span>
                <a href="#" class="d-none d-sm-inline-block btn btn-primary btn-sm shadow-sm" data-bs-toggle="modal"
                    data-bs-target="#pickConnectionWizardModal">Connect</a>
            </div>
            <div class="d-sm-flex align-items-center justify-content-between" v-if="project.connection">
                <span class="m-0 fw-bold" style="color:#0469B1">
                    {{ project.connection.name }}
                </span>
            </div>
            <div class="d-sm-flex align-items-center justify-content-between mt-2" v-if="project.connection">
                <span class="badge text-bg-danger" v-if="!project.connection.connectionEstablished">
                    Connection failed
                </span>
                <span class="badge text-bg-success" v-else>
                    Connected
                </span>
                <div>
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item dropdown no-arrow">
                            <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <h6><i class="fa-solid fa-ellipsis"></i></h6>
                            </a>
                            <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                                aria-labelledby="projectDropdown">
                                <!-- <a href="#" class="dropdown-item text-warning" style="color:#7B7B7B;" v-if="!editMode" data-bs-toggle="modal"
                                    data-bs-target="#updateConnectionWizardModal"><i
                                        class="fa fa-duotone fa-pen-to-square"></i> Edit connection</a> -->
                                <a href="#" class="dropdown-item text-danger" style="color:#7B7B7B;"
                                    v-on:click="removeConnection()"><i class="fa-solid fa-ban"></i>
                                    Disconnect</a>
                                <a href="#" class="dropdown-item text-info"
                                    v-if="!project.connection.connectionEstablished"
                                    v-on:click="testConnection(project.connectionConfigurationId)">
                                    <i class="fa-solid fa-plug"></i> Try to reconnect</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>

    <!-- Pick connection or create new one-->
    <div class="modal fade" id="pickConnectionWizardModal" tabindex="-1" role="dialog"
        aria-labelledby="pickConnectionWizardModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Pick connection or create new one</h5>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="ms-auto list-unstyled">
                        <li class="border mt-3 rounded" v-for="connection in connections" :key="connection.id"
                            v-on:click="selectConnection(connection.id)">
                            <div class="d-sm-flex align-items-center mt-2 justify-content-between">
                                <span class="m-0 fw-bold text-gray-800 ps-2">
                                    {{ connection.name }}
                                </span>
                                <i v-if="connection.id != selectedConnectionId"
                                    class="fa-sharp fa-regular fa-circle pe-2"></i>
                                <i v-if="connection.id == selectedConnectionId"
                                    class="fa-solid fa-circle-check pe-2 text-success"></i>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <a class="btn btn-primary" data-bs-dismiss="modal" v-on:click="pickConnection()">Connect</a>
                    <a class="btn btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" v-if="!editMode"
                        data-bs-target="#newConnectionWizardModal">Create new</a>
                </div>
            </div>
        </div>
    </div>

    <!-- Create new connection-->
    <div class="modal fade" id="newConnectionWizardModal" tabindex="-1" role="dialog"
        aria-labelledby="newConnectionWizardModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <img src="../assets/images/sap.svg" />
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                         
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="modal-title" id="exampleModalLabel">Setup connection</h5>
                    <form class="user mt-5">
                        <div class="mb-3 row">
                            <div class="col-sm-6 offset-sm-3 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.name"
                                    :class="{ 'border border-danger': nameError }"
                                    class="form-control form-control-user" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Name" id="name-new" placeholder="Name" autocomplete="name">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="password" v-model="connectionDto.username"
                                    :class="{ 'border border-danger': usernameError }" autocomplete="username"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Username"
                                    class="form-control form-control-user" id="username-new" placeholder="Username">
                            </div>
                            <div class="col-sm-6">
                                <input type="password" v-model="connectionDto.password"
                                    :class="{ 'border border-danger': passwordError }" autocomplete="new-password"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Password"
                                    class="form-control form-control-user" id="password-new" placeholder="Password">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.host"
                                    :class="{ 'border border-danger': hostError }"
                                    class="form-control form-control-user" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Host" id="host-new" placeholder="Host">
                            </div>
                            <div class="col-sm-6">
                                <input type="text" v-model="connectionDto.client"
                                    :class="{ 'border border-danger': clientError }"
                                    class="form-control form-control-user" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Client" id="client-new" placeholder="Client">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.language"
                                    :class="{ 'border border-danger': languageError }" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Language" class="form-control form-control-user"
                                    id="language-new" placeholder="Language">
                            </div>
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.systemNumber"
                                    :class="{ 'border border-danger': systemNumberError }" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="System number" class="form-control form-control-user"
                                    id="systemNumber-new" placeholder="System number">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.batchSize" data-bs-toggle="tooltip"
                                    :class="{ 'border border-danger': batchSizeError }" data-bs-placement="top"
                                    title="Batch size" class="form-control form-control-user" id="batchSize-new"
                                    placeholder="Batch size">
                            </div>
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.joinChunkSize" data-bs-toggle="tooltip"
                                    :class="{ 'border border-danger': joinChunkSizeError }" data-bs-placement="top"
                                    title="Join junk size" class="form-control form-control-user" id="JoinChunkSize-new"
                                    placeholder="Join junk size">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-12 mb-12 mb-sm-0">
                                <textarea type="text" class="form-control small shadow-sm" rows="8"
                                    v-model="connectionDto.description" placeholder="Connection description" id="connectionDescription-new"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Connection description"
                                    aria-label="Update" aria-describedby="basic-addon2" />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-md-12 mb-3 mb-sm-0">
                            <a href="#" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="createConnection()">
                                Save
                            </a>
                            <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-success ms-2"
                                v-if="connectionEstablished != null && connectionEstablished == true">
                                Test <i class="fa-sharp fa-solid fa-circle-check text-success"></i>
                            </a>
                            <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-danger ms-2"
                                v-else-if="connectionEstablished != null && connectionEstablished != true">
                                Test <i class="fa-sharp fa-solid fa-circle-check text-danger"></i>
                            </a>
                            <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-primary ms-2" v-else>
                                Test
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Update  connection-->
    <div class="modal fade" id="updateConnectionWizardModal" tabindex="-1" role="dialog"
        aria-labelledby="updateConnectionWizardModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <img src="../assets/images/sap.svg" />
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                         
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="modal-title" id="exampleModalLabel">Setup connection</h5>
                    <form class="user mt-5">
                        <div class="mb-3 row">
                            <div class="col-sm-6 offset-sm-3 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.name"
                                    :class="{ 'border border-danger': nameError }"
                                    class="form-control form-control-user" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Name" id="name-update" placeholder="Name" autocomplete="name">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="password" v-model="connectionDto.username"
                                    :class="{ 'border border-danger': usernameError }" autocomplete="username"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Username"
                                    class="form-control form-control-user" id="username-update" placeholder="Username">
                            </div>
                            <div class="col-sm-6">
                                <input type="password" v-model="connectionDto.password"
                                    :class="{ 'border border-danger': passwordError }" autocomplete="new-password"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Password"
                                    class="form-control form-control-user" id="password-update" placeholder="Password">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.host"
                                    :class="{ 'border border-danger': hostError }"
                                    class="form-control form-control-user" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Host" id="host-update" placeholder="Host">
                            </div>
                            <div class="col-sm-6">
                                <input type="text" v-model="connectionDto.client"
                                    :class="{ 'border border-danger': clientError }"
                                    class="form-control form-control-user" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Client" id="client-update" placeholder="Client">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.language"
                                    :class="{ 'border border-danger': languageError }" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Language" class="form-control form-control-user"
                                    id="language-update" placeholder="Language">
                            </div>
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.systemNumber"
                                    :class="{ 'border border-danger': systemNumberError }" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="System number" class="form-control form-control-user"
                                    id="systemNumber-update" placeholder="System number">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.batchSize" data-bs-toggle="tooltip"
                                    :class="{ 'border border-danger': batchSizeError }" data-bs-placement="top"
                                    title="Batch size" class="form-control form-control-user" id="batchSize-update"
                                    placeholder="Batch size">
                            </div>
                            <div class="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" v-model="connectionDto.joinChunkSize" data-bs-toggle="tooltip"
                                    :class="{ 'border border-danger': joinChunkSizeError }" data-bs-placement="top"
                                    title="Join junk size" class="form-control form-control-user"
                                    id="JoinChunkSize-update" placeholder="Join junk size">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-sm-12 mb-12 mb-sm-0">
                                <textarea type="text" class="form-control small shadow-sm" rows="8"
                                    v-model="connectionDto.description" placeholder="Connection description" id="connectionDescription-update"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Connection description"
                                    aria-label="Update" aria-describedby="basic-addon2" />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-md-12 mb-3 mb-sm-0">
                            <a href="#" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="updateConnection()">
                                Save
                            </a>
                            <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-success ms-2"
                                v-if="connectionEstablished != null && connectionEstablished == true">
                                Test <i class="fa-sharp fa-solid fa-circle-check text-success"></i>
                            </a>
                            <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-danger ms-2"
                                v-else-if="connectionEstablished != null && connectionEstablished != true">
                                Test <i class="fa-sharp fa-solid fa-circle-check text-danger"></i>
                            </a>
                            <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-primary ms-2" v-else>
                                Test
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ConnectionsService from "../services/connectionsService";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ProjectsService from "../services/projectsService";

export default {
    props: ['project', 'title', 'description', 'editMode'],
    emits: ["connectionChanged"],
    data: () => {
        return {
            connectionDto: {
                name: null,
                client: null,
                username: null,
                password: null,
                host: null,
                systemNumber: null,
                language: null,
                joinChunkSize: 1000,
                batchSize: 100000
            },
            connections: [],
            selectedConnectionId: null,
            connectionEstablished: null,
            nameError: false,
            descriptionError: false,
            clientError: false,
            usernameError: false,
            passwordError: false,
            hostError: false,
            systemNumberError: false,
            languageError: false,
            joinChunkSizeError: false,
            batchSizeError: false
        };
    },
    created() {
        this.getConnections();

        if (this.project.connection) {
            this.connectionDto = this.project.connection;
        }
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredConnections = this.connections.filter(x => x.name.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1 || x.description?.toLowerCase()?.indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredConnections = this.connections
            }
        },
        'connectionDto.name': function (newTerm) {
            if (!newTerm) {
                this.nameError = true;
            } else {
                this.nameError = false;
            }
        },
        'connectionDto.client': function (newTerm) {
            if (!newTerm) {
                this.clientError = true;
            } else {
                this.clientError = false;
            }
        },
        'connectionDto.username': function (newTerm) {
            if (!newTerm) {
                this.usernameError = true;
            } else {
                this.usernameError = false;
            }
        },
        'connectionDto.password': function (newTerm) {
            if (!newTerm) {
                this.passwordError = true;
            } else {
                this.passwordError = false;
            }
        },
        'connectionDto.systemNumber': function (newTerm) {
            if (!newTerm) {
                this.systemNumberError = true;
            } else {
                this.systemNumberError = false;
            }
        },
        'connectionDto.language': function (newTerm) {
            if (!newTerm) {
                this.languageError = true;
            } else {
                this.languageError = false;
            }
        },
        'connectionDto.batchSize': function (newTerm) {
            if (!newTerm) {
                this.batchSizeError = true;
            } else {
                this.batchSizeError = false;
            }
        },
        'connectionDto.joinChunkSize': function (newTerm) {
            if (!newTerm) {
                this.joinChunkSizeError = true;
            } else {
                this.joinChunkSizeError = false;
            }
        },
        'connectionDto.host': function (newTerm) {
            if (!newTerm) {
                this.hostError = true;
            } else {
                this.hostError = false;
            }
        },
    },
    methods: {
        handleError(error) {
            this.loading = false;
            const $toast = useToast();
            $toast.error(error.message);
        },
        getConnections() {
            ConnectionsService.getConnections().then((connections) => { this.connections = connections; });
        },
        testConnection(id) {
            ConnectionsService.testConnection(id)
                .then(() => {
                    this.$emit('connectionChanged');
                    const $toast = useToast();
                    $toast.success("Connection added!");
                })
                .catch(error => {
                    this.handleError(error);
                })
        },
        testConnectionDto() {
            ConnectionsService.testConnectionDto(this.connectionDto)
                .then((isOk) => {
                    this.$emit('connectionChanged');
                    this.connectionEstablished = isOk;
                })
                .catch(error => {
                    this.handleError(error);
                })
        },
        selectConnection(id) {
            this.selectedConnectionId = id;
        },
        pickConnection() {
            ProjectsService.addConnection(this.project.id, this.selectedConnectionId)
                .then(() => {
                    this.$emit('connectionChanged');
                    const $toast = useToast();
                    $toast.success("Connection added!");
                })
                .catch(error => {
                    this.handleError(error);
                })
        },
        removeConnection() {
            ProjectsService.removeConnection(this.project.id)
                .then(() => {
                    this.$emit('connectionChanged');
                    const $toast = useToast();
                    $toast.success("Connection removed!");
                })
                .catch(error => {
                    this.handleError(error);
                })
        },
        createConnection() {

            this.$parent.loading = true;
            this.connectionDto.connectionEstablished = this.connectionEstablished;

            let joinChunkSize = parseInt(this.connectionDto.joinChunkSize);

            if (typeof joinChunkSize != 'number' || joinChunkSize < 1 || isNaN(joinChunkSize)) {
                const $toast = useToast();
                $toast.error('Join chunk size must be a positive number bigger then zero (0)');
            }

            let batchSize = parseInt(this.connectionDto.batchSize);

            if (typeof batchSize != 'number' || batchSize < 1 || isNaN(batchSize)) {
                const $toast = useToast();
                $toast.error('Batch size must be a positive number bigger then zero (0)');
            }


            ConnectionsService.createConnection(this.connectionDto).then((id) => {
                ProjectsService.addConnection(this.project.id, id)
                    .then(() => {
                        this.$emit('connectionChanged');
                        const $toast = useToast();
                        $toast.success("Connection added!");
                    })
                    .catch(error => {
                        this.handleError(error);
                    })
            })
                .catch(error => {
                    this.handleError(error);
                })
        },
        updateConnection() {

            this.loading = true;
            this.connectionDto.connectionEstablished = this.connectionEstablished;

            ConnectionsService.updateConnection(this.connectionDto.id, this.connectionDto).then(() => {
                this.$emit('connectionChanged');
                const $toast = useToast();
                $toast.success("Connection added!");
            })
                .catch(error => {
                    this.handleError(error);
                })
        },
    },
}
</script>