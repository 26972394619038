<template>
    <div class="dropdown">
        <button class="btn btn-sm btn-default dropdown-toggle custom-dropdown-toggle" type="button"
            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa-solid fa-ellipsis"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="customDropdown">
            <slot></slot>
        </ul>
    </div>
</template>

<script>

export default {
}
</script>