<template>
    <div class="profile" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h6 class="h6 text-gray-600" v-if="!loading">
                Profile / <span class="fw-bold"> {{ user.firstName }} {{ user.lastName }} </span> with role <span class="fw-bold">{{ user.mainRole }}</span> in <span class="fw-bold">{{ user.tenantName }}</span>
            </h6>
        </div>

        <!-- Content Row -->
        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-5 offset-lg-4 col-sm-12 mx-auto mb-4 ms-5 mt-4">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="row">
                            <form class="user mt-5 offset-3">
                                <div class="d-sm-flex align-items-center justify-content-between mb-5"  >
                                    <h3 class="m-0 fw-bold">
                                        Profile
                                    </h3>
                                    <ul class="navbar-nav ms-auto">
                                        <li class="nav-item dropdown no-arrow">
                                            <a class="nav-link dropdown-toggle custom-dropdown-toggle" href="#" id="projectDropdown" role="button"
                                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                                                aria-labelledby="projectDropdown">
                                                <router-link href="#" active-class="none" class="dropdown-item text-warning"  style="font-weight: 100;" exact-active-class="none" to="/edit-profile" ><i class="fa-regular fa-pen-to-square"></i>
                                                    Edit
                                                  </router-link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row">
                                    <div class="mb-3 col-md-6 col-lg-6">
                                        <i class="fa fa-light fa-signature"> </i> 
                                        <label class="ms-1"> First name</label>
                                        <p style="min-width: 300px;">{{user.firstName}}</p>
                                    </div>
                                    <div class="mb-3 col-md-6 col-lg-6">
                                        <i class="fa fa-light fa-signature"></i>
                                        <label class="ms-1"> Last name</label>
                                        <p>{{user.lastName}}</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <i class="fa fa-light fa-address-book"> </i> 
                                    <label class="ms-1"> Address line 1 </label>
                                    <p>{{user.address}}</p>
                                </div>
                                <div class="mb-3">
                                    <i class="fa fa-light fa-address-book"></i>
                                    <label class="ms-1"> Address line 2</label>
                                    <p>{{user.streetNumber}}</p>
                                </div>
                                <div class="row">
                                    <div class="mb-3 col-md-6 col-lg-6">
                                        <i class="fa-sharp fa-solid fa-location-pin"></i>
                                        <label class="ms-1"> City</label> 
                                        <p>{{user.city}}</p>
                                    </div>
                                    <div class="mb-3 col-md-6 col-lg-6">
                                        <i class="fa-solid fa-signs-post"></i>
                                        <label class="ms-1"> Postal code</label>
                                        <p>{{user.postCode}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="mb-3 col-md-6 col-lg-6">
                                        <i class="fa-solid fa-globe"></i>
                                        <label class="ms-1"> Country</label>
                                        <p>{{user.country}}</p>
                                    </div>
                                    <div class="mb-3 col-md-6 col-lg-6">
                                        <i class="fa-solid fa-phone"></i>
                                        <label class="ms-1"> Phone number</label>
                                        <p>{{user.phoneNumber}}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingIndicator from "../components/LoadingIndicator.vue";
import UsersService from "../services/usersService";

export default {
    name: "ProfileView",
    data: () => {
        return {
            user: {
                firstName: null,
                lastName: null,
                phoneNumber: null,
                postCode: null,
                address: null,
                city: null,
                country: null,
                streetNumber: null
            },
            loading: true,
            firstNameError: false,
            lastNameError: false
        };
    },
    components: {
        LoadingIndicator,
    },
    created() {
        this.getProfile();
    },
    methods: {
        getProfile() {
            UsersService.getCurrent().then(
                (user) => {
                    this.user = user;
                    this.loading = false;
                })
        }
    },
};
</script>
