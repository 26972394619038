<template>  
    <router-view></router-view>
</template>
<script>
export default {
  created () {
    const access_token = JSON.parse(localStorage.getItem("access_token") || "{}");
    if( access_token !== null && typeof access_token === 'object' && Object.prototype.toString.call(access_token) === '[object Object]')
    {
      return;
    }

    this.$socket.authenticate(access_token,{
        log: true // Logging is optional but very helpful during development
      })
  },
  updated () {
    const access_token = JSON.parse(localStorage.getItem("access_token") || "{}");
    if( access_token !== null && typeof access_token === 'object' && Object.prototype.toString.call(access_token) === '[object Object]')
    {
      return;
    }
    
    this.$socket.authenticate(access_token,{
        log: true // Logging is optional but very helpful during development
      })
  },
  sockets: {
    ProjectExtractionStatus () {
      // this has to be here even though it's not being used?
      // otherwise other events don't actually fire
    },
    ProjectJobExtractionStatus(){
      
    },
    JobExtractionInfo(){
      
    }
  }
}
</script>