<template>
  <div class="dashboard">
    Dashboard
  </div>
</template>

<script>

export default {
  name: "DashboardView",
  components: {},
  data() {
    return {
      toasts: []
    }
  },
  created() {
  },
  methods: {}
}

</script>
